import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../context/AuthProvider'

const PrivateOutlet: React.FC = () => {
  const auth = useAuth()

  return auth?.bearerToken ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to='/login' replace />
  )
}

export default PrivateOutlet
