import React, { useContext } from 'react'
import { LinearProgress } from '@mui/material'
import PageHeader from '../../components/PageHeader'

import { UserContext } from '../../context'
import { isCurrentAnyMemberOfFMAOrOtherOrganization } from '../../utils/membershipChecks'
import MainInfo from './MainInfo'
import MembershipInfo from './MembershipInfo'
import Merchandice from './Merchandice'
import PersonalInfo from './PersonalInfo'
import WorkInfo from './WorkInfo'
import Qualifications from './Qualifications'
import Applications from './Applications'

const LandingPage = () => {
  const applications = true
  const { user } = useContext(UserContext)
  const userIsCurrentMember = isCurrentAnyMemberOfFMAOrOtherOrganization(user)

  return user ? (
    <>
      <PageHeader />
      <MainInfo />
      {userIsCurrentMember && <PersonalInfo />}
      {applications && <Applications />}
      {userIsCurrentMember && <Qualifications />}
      {userIsCurrentMember && <WorkInfo />}
      <MembershipInfo />
      {userIsCurrentMember && <Merchandice />}
    </>
  ) : (
    <LinearProgress />
  )
}

export default LandingPage
