import React from 'react'
import { Tooltip, IconButton } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import { buildCsvLine, parseDate } from '../utils/utils'
import { membershipStartingTimes } from '../utils/stringConstants'

interface Props {
  selectedDataSet: any
  applicationType: string
}

interface ResignationData {
  id: string
  data: {
    formData: {
      membership: {
        els_jasentyyppi: string
        yhdistys_name: string
        els_liitonjasenyysid: string
      }
      timeOfResignation: string
      bankAccount: string
    }
    aapeliData: {
      firstname: string
      lastname: string
      nickname: string
    }
    personIdentity: {
      contactid: string
      els_henkilonumero: string
    }
    state: string
    submitted: string
    stateChanged: string
  }
}

interface DiscountData {
  id: string
  data: {
    submitted: string
    state: string
    stateChanged: string
    personIdentity: {
      contactid: string
      els_henkilonumero: string
    }
    formData: {
      attachments: [
        {
          originalName: string
          description: string
          storageName: string
          url: string
        }
      ]
      bankAccount: string
      els_alennuspaattyen: string
      els_alennus: {
        els_alennusid: string
        els_alennuksennimi: string
      }
      els_alennusalkaen: string
      memberSinceYear: string
    }
    aapeliData: {
      firstname: string
      lastname: string
      nickname: string
    }
  }
}

interface StampOrderData {
  id: string
  data: {
    submitted: string
    state: string
    stateChanged: string
    personIdentity: {
      contactid: string
      els_henkilonumero: string
    }
    formData: {
      isPaid: boolean
      stampInfo: {
        name: string
        title: string
        terhikki: string
        quantity: number
        type: string
      }
      deliveryAddress: {
        name: string
        streetAddress: string
        postalCode: string
        city: string
        country: string
        mobilePhone: string
      }
    }
  }
}

interface MemberData {
  id: string
  data: {
    personIdentity: { els_henkilonumero?: string; els_hetu?: string }
    state: string
    submitted: string
    stateChanged: string
    formData: {
      lastname: string
      birthdate: string
      nickname: string
      mobilephone: string
      address: {
        line1: string
        els_postikieli: { value: string; attributevalue: number }
        postalcode: string
        city: string
        country: { els_koodiid: string; els_koodinnimi: string }
      }
      firstname: string
      cursus?: {
        els_oppilaitos: {
          els_koodiid: string
          els_koodinnimi: string
        }
        els_aloitusvuosi: number
        els_lukukausi: {
          attributevalue: number
          value: string
        }
      }
      organization: {
        accountid: string
        name: string
      }
      emailaddress1: string
      employment?: {
        tyosuhdetoimiala: { value: string; attributevalue: number }
        els_soteorganisaatio: {
          els_longname: string
          els_soteorganisaatiorekisteriid: string
        }
        els_alkupvm: string
        ammattinimike: {
          els_koodinnimi: string
          els_koodiid: string
        }
        els_paatoimi: boolean
        els_tyosuhdesektori: { attributevalue: number; value: string }
      }
      formType: string
      terhikkireknro: string
      membership_starts: string
    }
  }
}

interface StudentData {
  id: string
  data: {
    formData: {
      firstname?: string
      lastname?: string
      terhikkireknro?: string
      formType: string
      birthdate: string
      cursus?: {
        els_oppilaitos: {
          els_koodiid: string
          els_koodinnimi: string
        }
        els_aloitusvuosi: number
        els_lukukausi: {
          attributevalue: number
          value: string
        }
      }
      nickname?: string
      emailaddress1: string
      organization: {
        accountid: string
        name: string
      }
      address: {
        city: string
        country: {
          els_koodinnimi: string
          els_koodiid: string
        }
        line1: string
        els_postikieli: {
          value: string
          attributevalue: number
        }
        postalcode: string
      }
      employment?: {
        els_soteorganisaatio: {
          els_longname: string
          els_soteorganisaatiorekisteriid: string
        }
        tyosuhdetoimiala: {
          value: string
          attributevalue: number
        }
        els_alkupvm: string
        els_paatoimi: false
        els_tyosuhdesektori: {
          value: string
          attributevalue: number
        }
        ammattinimike: {
          els_koodiid: string
          els_koodinnimi: string
        }
      }
      mobilephone: string
    }
    state: string
    submitted: string
    stateChanged: string
    personIdentity: {
      els_henkilonumero?: string
      els_hetu?: string
    }
  }
}

const ExportCsvButton: React.FC<Props> = ({ selectedDataSet, applicationType }) => {
  const generateMemberCsv = () => {
    const titleLine = buildCsvLine([
      'id',
      'Hakemuksen tila',
      'Tila päivitetty',
      'Jäsenyys alkaa',
      'Henkilönumero/Henkilötunnus',
      'Etunimet',
      'Sukunimi',
      'Kutsumanimi',
      'Syntymäaika',
      'Sähköpostiosoite',
      'Matkapuhelinnumero',
      'Maa',
      'Postitoimipaikka',
      'Postiosoite',
      'Postituskieli',
      'Postinumero',
      'Terhikkinumero',
      'Ammattinimike',
      'Työsuhdesektori',
      'Päätoiminen',
      'Toimiala',
      'Soteorganisaatio',
      'Työn aloituspäivämäärä',
      'Organisaatio',
      'Oppilaitos',
      'Opiskelujen aloitusvuosi',
      'Opiskelujen aloituslukukausi',
      'Lähetetty'
    ])
    const applications: MemberData[] = selectedDataSet
    const dataLines = applications.map(application => {
      const {
        id,
        data: {
          personIdentity: { els_henkilonumero, els_hetu } = {},
          state,
          stateChanged,
          submitted,
          formData: {
            lastname,
            birthdate,
            nickname,
            mobilephone,
            address: {
              line1,
              els_postikieli: { value: mailLanguageValue } = {},
              postalcode,
              city,
              country: { els_koodinnimi: countryCodename } = {}
            },
            firstname,
            cursus: {
              els_oppilaitos: { els_koodinnimi: school = '' } = {},
              els_aloitusvuosi,
              els_lukukausi: { value: lukukausiAttribute = '' } = {}
            } = {},
            organization: { name: orgName },
            emailaddress1,
            employment: {
              tyosuhdetoimiala: { value: toimialaValue = '' } = {},
              els_soteorganisaatio: { els_longname: soteName = '' } = {},
              els_alkupvm,
              ammattinimike: { els_koodinnimi: titleCodeName = '' } = {},
              els_paatoimi,
              els_tyosuhdesektori: { value: sektoriValue = '' } = {}
            } = {},
            terhikkireknro = '',
            membership_starts
          }
        }
      } = application

      return buildCsvLine([
        id,
        state,
        stateChanged,
        membershipStartingTimes[membership_starts] || membership_starts,
        els_henkilonumero || els_hetu,
        firstname,
        lastname,
        nickname,
        birthdate,
        emailaddress1,
        mobilephone,
        countryCodename,
        city,
        line1,
        mailLanguageValue,
        postalcode,
        terhikkireknro,
        titleCodeName,
        sektoriValue,
        els_paatoimi,
        toimialaValue,
        soteName,
        els_alkupvm,
        orgName,
        school,
        els_aloitusvuosi,
        lukukausiAttribute,
        submitted
      ])
    })
    return `${titleLine}\n${dataLines.join('\n')}\n`
  }

  const generateResignationCsv = () => {
    const titleLine = buildCsvLine([
      'id',
      'Organisaatio',
      'Jäsentyyppi',
      'Eroamispäivä',
      'Tilinumero',
      'Henkilönumero',
      'Etunimet',
      'Sukunimi',
      'Kutsumanimi',
      'Lähetetty',
      'Hakemuksen tila',
      'Tila päivitetty'
    ])
    const applications: ResignationData[] = selectedDataSet
    const dataLines = applications.map(application => {
      const {
        id,
        data: {
          formData: {
            membership: { els_jasentyyppi, yhdistys_name } = {},
            timeOfResignation,
            bankAccount
          },
          aapeliData: { firstname, lastname, nickname },
          personIdentity: { els_henkilonumero } = {},
          state,
          submitted,
          stateChanged
        }
      } = application

      return buildCsvLine([
        id,
        yhdistys_name,
        els_jasentyyppi,
        timeOfResignation,
        bankAccount,
        els_henkilonumero,
        firstname,
        lastname,
        nickname,
        submitted,
        state,
        stateChanged
      ])
    })
    return `${titleLine}\n${dataLines.join('\n')}\n`
  }

  const generateDiscountCsv = () => {
    const titleLine = buildCsvLine([
      'id',
      'Tilinumero',
      'Alennuksen nimi',
      'Alennus päättyen',
      'Alennus alkaen',
      'Toisen liiton jäsen vuodesta',
      'Lähetetty',
      'Hakemuksen tila',
      'Tila päivitetty',
      'Henkilönumero',
      'Etunimet',
      'Sukunimi',
      'Kutsumanimi'
    ])
    const applications: DiscountData[] = selectedDataSet
    const dataLines = applications.map(application => {
      const {
        id,
        data: {
          submitted,
          state,
          stateChanged,
          personIdentity: { els_henkilonumero },
          formData: {
            bankAccount,
            els_alennuspaattyen,
            els_alennusalkaen,
            els_alennus: { els_alennuksennimi } = {},
            memberSinceYear
          },
          aapeliData: { firstname, lastname, nickname }
        }
      } = application

      return buildCsvLine([
        id,
        bankAccount,
        els_alennuksennimi,
        els_alennuspaattyen,
        els_alennusalkaen,
        memberSinceYear,
        submitted,
        state,
        stateChanged,
        els_henkilonumero,
        firstname,
        lastname,
        nickname
      ])
    })
    return `${titleLine}\n${dataLines.join('\n')}\n`
  }

  const generateStampOrderCsv = () => {
    const titleLine = buildCsvLine([
      'nimi',
      'osoite',
      'postinumero',
      'postitoimipaikka',
      'matkapuhelin',
      'leimasinnimi',
      'titteli',
      'yksilöintitunnus',
      'kpl',
      'tyyppi',
      'maksullinen',
      'pvm'
    ])
    const applications: StampOrderData[] = selectedDataSet
    const dataLines = applications.map(application => {
      const {
        data: {
          submitted,
          formData: { isPaid, deliveryAddress, stampInfo }
        }
      } = application

      return buildCsvLine([
        deliveryAddress?.name,
        deliveryAddress?.streetAddress,
        deliveryAddress?.postalCode,
        deliveryAddress?.city,
        deliveryAddress?.mobilePhone,
        stampInfo?.name,
        stampInfo?.title,
        stampInfo?.terhikki,
        stampInfo?.quantity,
        stampInfo?.type,
        isPaid ? 'yes' : 'no',
        parseDate(submitted, 'yyyyMMdd')
      ])
    })
    return `${titleLine}\n${dataLines.join('\n')}\n`
  }

  const generateStudentCsv = () => {
    const titleLine = buildCsvLine([
      'id',
      'Hakemuksen tila',
      'Tila päivitetty',
      'Henkilönumero/Henkilötunnus',
      'Etunimet',
      'Sukunimi',
      'Kutsumanimi',
      'Syntymäaika',
      'Sähköpostiosoite',
      'Matkapuhelinnumero',
      'Maa',
      'Postitoimipaikka',
      'Postiosoite',
      'Postituskieli',
      'Postinumero',
      'Terhikkinumero',
      'Ammattinimike',
      'Työsuhdesektori',
      'Päätoiminen',
      'Toimiala',
      'Soteorganisaatio',
      'Työn aloituspäivämäärä',
      'Organisaatio',
      'Oppilaitos',
      'Opiskelujen aloitusvuosi',
      'Opiskelujen aloituslukukausi',
      'Lähetetty'
    ])
    const applications: StudentData[] = selectedDataSet
    const dataLines = applications.map(application => {
      const {
        id,
        data: {
          formData: {
            firstname,
            lastname,
            terhikkireknro,
            birthdate,
            cursus: {
              els_oppilaitos: { els_koodinnimi: school = '' } = {},
              els_aloitusvuosi,
              els_lukukausi: { value: lukukausiAttribute = '' } = {}
            } = {},
            nickname,
            emailaddress1,
            organization: { name: orgName } = {},
            address: {
              city,
              country: { els_koodinnimi: countryCodename } = {},
              line1,
              els_postikieli: { value: mailLanguageValue } = {},
              postalcode
            },
            employment: {
              els_soteorganisaatio: { els_longname: soteName = '' } = {},
              tyosuhdetoimiala: { value: toimialaValue = '' } = {},
              els_alkupvm,
              els_paatoimi,
              els_tyosuhdesektori: { value: sektoriValue = '' } = {},
              ammattinimike: { els_koodinnimi: titleCodeName = '' } = {}
            } = {},
            mobilephone
          },
          submitted,
          state,
          stateChanged,
          personIdentity: { els_henkilonumero, els_hetu } = {}
        }
      } = application

      return buildCsvLine([
        id,
        state,
        stateChanged,
        els_henkilonumero || els_hetu,
        firstname,
        lastname,
        nickname,
        birthdate,
        emailaddress1,
        mobilephone,
        countryCodename,
        city,
        line1,
        mailLanguageValue,
        postalcode,
        terhikkireknro,
        titleCodeName,
        sektoriValue,
        els_paatoimi,
        toimialaValue,
        soteName,
        els_alkupvm,
        orgName,
        school,
        els_aloitusvuosi,
        lukukausiAttribute,
        submitted
      ])
    })
    return `${titleLine}\n${dataLines.join('\n')}\n`
  }

  const downloadCsvFile = (csv: string, fileName: string) => {
    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv)
    hiddenElement.target = '_blank'
    hiddenElement.download = fileName
    hiddenElement.click()
  }

  let csv: any

  applicationType === 'STUDENT' && (csv = generateStudentCsv())
  applicationType === 'DISCOUNT' && (csv = generateDiscountCsv())
  applicationType === 'RESIGNATION' && (csv = generateResignationCsv())
  applicationType === 'MEMBER' && (csv = generateMemberCsv())
  applicationType === 'STAMP_ORDER' && (csv = generateStampOrderCsv())

  return (
    <>
      <Tooltip title='Export csv'>
        <IconButton onClick={() => downloadCsvFile(csv, applicationType)}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default ExportCsvButton
