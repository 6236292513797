import { Typography } from '@mui/material'
import React from 'react'

import { ResultsP, FormLabel } from '../../../components/styled/uiComponents'
import { parseDate } from '../../../utils/utils'

interface Props {
  selectedApplication: any
  applicationType: string
}
const ResignationApplicationDetails: React.FC<Props> = ({
  selectedApplication,
  applicationType
}) => {
  const application = {
    ...selectedApplication.data.formData,
    ...selectedApplication.data.aapeliData,
    handlingEvents: selectedApplication.data.handlingEvents,
    identity: selectedApplication.data.personIdentity
  }

  return (
    <>
      <div>
        <Typography variant='h4' color='primary'>
          Hakemuksen tyyppi: {applicationType}
        </Typography>
      </div>
      <div>
        <FormLabel>Sukunimi</FormLabel>
        <ResultsP>{application.lastname}</ResultsP>
      </div>

      <div>
        <FormLabel>Etunimet</FormLabel>
        <ResultsP>{application.firstname}</ResultsP>
      </div>

      <div>
        <FormLabel>Henkilönumero</FormLabel>
        <ResultsP>{application.identity?.els_henkilonumero}</ResultsP>
      </div>

      <div>
        <FormLabel>Jäsenyys</FormLabel>
        <ResultsP>{application.membership.els_jasentyyppi}</ResultsP>
      </div>

      <div>
        <FormLabel>Yhdistyksen nimi</FormLabel>
        <ResultsP>{application.membership.yhdistys_name}</ResultsP>
      </div>
      <div>
        <FormLabel>Eroamisaika</FormLabel>
        <ResultsP>{application.timeOfResignation}</ResultsP>
      </div>
      <div>
        <FormLabel>Tilinumero</FormLabel>
        <ResultsP>{application.bankAccount}</ResultsP>
      </div>

      <div>
        <FormLabel>Hakemus lähetetty</FormLabel>
        <ResultsP>{parseDate(selectedApplication.data.submitted)}</ResultsP>
      </div>

      {application.handlingEvents && (
        <div>
          <FormLabel>Käsittelytapahtumat</FormLabel>
          {application.handlingEvents.map((handlingEvent: any, i: number) => (
            <div key={i}>
              <ResultsP>
                {handlingEvent.timestamp} - {handlingEvent.operation?.name} -{' '}
                {handlingEvent.handler?.name}
              </ResultsP>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default ResignationApplicationDetails
