import React from 'react'

import styled from '@emotion/styled'

interface IComponentProps {
  jobInfo?: string
}

const JobContainer = styled.div`
  border: 1px solid #b6b6b6;
  border-radius: 3px;
  margin-bottom: 1vh;
  padding: 3vh;
`

const JobResultRow = (props: IComponentProps) => {
  const { jobInfo } = props

  return (
    <JobContainer>
      <p>{jobInfo}</p>
    </JobContainer>
  )
}

export default JobResultRow
