import React, { useContext } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import { CircularProgress, Grid } from '@mui/material'
import { format, subYears } from 'date-fns'
import { AuthContext, useAuth } from '../context/AuthProvider'
import PageHeader from '../components/PageHeader'
import { LaakariButton, ShadowContainer } from '../components/styled/uiComponents'
import MemberCard from '../memberCard/MemberCard'

interface StateType {
  from: { pathname: string }
}

const LoginPage = () => {
  const { isLoadingAuth } = useContext(AuthContext)

  const location = useLocation()
  const state = location.state as StateType
  const from = state?.from?.pathname || '/'

  const navigate = useNavigate()
  const auth = useAuth()

  const handleLogin = (event: any) => {
    event.preventDefault()

    auth.handleLogin(() => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true })
    })
  }

  return (
    <>
      <PageHeader />
      <Grid
        container
        alignItems='center'
        direction='column'
        sx={{
          margin: 'auto',
          width: '80%',
          paddingTop: '25vh'
        }}>
        <Grid
          item
          xs={12}
          sx={{
            width: '80%'
          }}>
          <ShadowContainer>
            {!isLoadingAuth ? (
              <Grid container alignItems='center' direction='column'>
                <Grid item xs={12}>
                  <h1>Tietoni</h1>
                </Grid>
                <Grid item xs={12}>
                  <LaakariButton disabled={isLoadingAuth} onClick={handleLogin}>
                    Kirjaudu
                  </LaakariButton>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} sx={{ margin: 'auto', width: '80%', textAlign: 'center' }}>
                <CircularProgress />
              </Grid>
            )}
          </ShadowContainer>
        </Grid>
      </Grid>
      {/*<MemberCard />*/}
    </>
  )
}

export default LoginPage
