import * as yup from 'yup'

const phoneRegEx = /\+[\d -]+/

export const personalInfoSchema = yup.object().shape({
  els_hetu: yup.string(),
  contactInfo: yup.object().shape({
    lastname: yup.string().required(''),
    firstname: yup.string().max(50).required(),
    nickname: yup.string(),
    line1: yup.string().max(250).required('Täytä puuttuva kenttä'),
    line2: yup.string().max(250).optional(),
    line3: yup.string().max(250).optional(),
    postalcode: yup.string().max(20).required('Täytä puuttuva kenttä'),
    city: yup.string().max(80).required('Täytä puuttuva kenttä'),
    els_postikieli: yup.string().required('Täytä puuttuva kenttä'),

    mobilephone: yup
      .string()
      .max(50)
      .matches(phoneRegEx, 'Puhelinnumeron tulee olla kansainvälisessä muodossa (+358)')
      .required(),
    emailaddress1: yup
      .string()
      .max(100)
      .email('Syötä validi sähköpostiosoite')
      .required('Täytä puuttuva kenttä'),
    cursus: yup.object().shape({
      els_oppilaitos: yup.string().required('Täytä puuttuva kenttä'),
      els_aloitusvuosi: yup.string().required('Täytä puuttuva kenttä'),
      els_lukukausi: yup.string().required('Täytä puuttuva kenttä')
    })
  })
})
