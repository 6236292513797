import * as yup from 'yup'
import { validateIBAN } from '../../../../utils/utils'

const formWithAttachmentSchema = yup.object().shape({
  els_alennus: yup.object().shape({
    els_alennuksennimi: yup.string()
  }),
  els_hetu: yup.string(),
  els_alennusalkaen: yup.string().required('Täytä puuttuva kenttä'),
  els_alennuspaattyen: yup.string().required('Täytä puuttuva kenttä'),
  bankAccount: yup
    .string()
    .required('Täytä puuttuva kenttä')
    .test('valid-iban', 'Syötä tilinumero IBAN-muodossa', validateIBAN),
  attachments: yup.mixed().required('Lisää liitetiedosto')
  /*
  attachments: yup
    .mixed()
    .required('Lisää liitetiedosto')
    .test('fileSize', 'The file is too large', value => {
      if (!value.length) {
        return true
      } // attachment is optional
      return value[0].size <= 2000000
    })
  */
})

const formWithAttachmentAndNoEndDateSchema = yup.object().shape({
  els_alennus: yup.object().shape({
    els_alennuksennimi: yup.string()
  }),
  els_hetu: yup.string(),
  els_alennusalkaen: yup.string().required('Täytä puuttuva kenttä'),
  bankAccount: yup
    .string()
    .required('Täytä puuttuva kenttä')
    .test('valid-iban', 'Syötä tilinumero IBAN-muodossa', validateIBAN),
  attachments: yup.mixed().required('Lisää liitetiedosto')
})

const formWithNoAttachmentAndNoEndDateSchema = yup.object().shape({
  els_alennus: yup.object().shape({
    els_alennuksennimi: yup.string()
  }),
  els_hetu: yup.string(),
  els_alennusalkaen: yup.string().required('Täytä puuttuva kenttä'),
  bankAccount: yup
    .string()
    .required('Täytä puuttuva kenttä')
    .test('valid-iban', 'Syötä tilinumero IBAN-muodossa', validateIBAN)
})

const formWithNoAttachmentSchema = yup.object().shape({
  els_alennus: yup.object().shape({
    els_alennuksennimi: yup.string()
  }),
  els_hetu: yup.string(),
  els_alennusalkaen: yup.string().required('Täytä puuttuva kenttä'),
  els_alennuspaattyen: yup.string().required('Täytä puuttuva kenttä'),
  bankAccount: yup
    .string()
    .required('Täytä puuttuva kenttä')
    .test('valid-iban', 'Syötä tilinumero IBAN-muodossa', validateIBAN)
})

const unionFormSchema = yup.object().shape({
  els_alennus: yup.object().shape({
    els_alennuksennimi: yup.string().required('Täytä puuttuva kenttä')
  }),
  els_hetu: yup.string(),
  memberSinceYear: yup.string().required('Täytä puuttuva kenttä'),
  bankAccount: yup
    .string()
    .required('Täytä puuttuva kenttä')
    .test('valid-iban', 'Syötä tilinumero IBAN-muodossa', validateIBAN)
})

const emptySchema = yup.object().shape({})

export const allSchemas = [
  formWithNoAttachmentAndNoEndDateSchema,
  formWithAttachmentSchema,
  formWithNoAttachmentSchema,
  formWithAttachmentAndNoEndDateSchema,
  unionFormSchema,
  emptySchema
]

export const getSchema = (hasEndDate: boolean, hasFileUpload: boolean, id?: number) => {
  if (!hasEndDate && !hasFileUpload && id !== 6) {
    return 0
  } else if (!!hasEndDate && !!hasFileUpload) {
    return 1
  } else if (!!hasEndDate && !hasFileUpload) {
    return 2
  } else if (!hasEndDate && !!hasFileUpload) {
    return 3
  } else if (id === 6) {
    return 4
  } else {
    return 5
  }
}
