import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from '@mui/material/'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthProvider'
import { LaakariButton } from './styled/uiComponents'

interface Props {
  open: boolean
  handleClose: () => void
}

const LogoutConfirmation: React.FC<Props> = ({ open, handleClose }) => {
  const auth = useAuth()
  const navigate = useNavigate()

  const handleLogout = () => {
    auth.handleLogout(() => navigate('/'))
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Grid container>
        <Grid item xs={12}>
          <DialogTitle>{'Haluatko varmasti kirjautua ulos?'}</DialogTitle>
        </Grid>
        <Grid item xs={12}>
          <DialogContent>
            <DialogContentText>Oletko varma, että haluat kirjautua ulos?</DialogContentText>
          </DialogContent>
        </Grid>
        <Grid item xs={12}>
          <DialogActions>
            <Grid container item justifyContent='space-between' direction='row'>
              <Grid item xs={5}>
                <LaakariButton onClick={handleClose}>Ei</LaakariButton>
              </Grid>
              <Grid item xs={5} sx={{ textAlign: 'right' }}>
                <LaakariButton variant='outlined' onClick={handleLogout} autoFocus>
                  Kyllä
                </LaakariButton>
              </Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default LogoutConfirmation
