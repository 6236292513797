import React, { Fragment } from 'react'
import { FormLabel } from '@mui/material'
import { ResultsP } from '../../../components/styled/uiComponents'
import { Job } from '../../../types/job'

interface JobSummaryProps {
  job: Job
}

const JobSummary: React.FC<JobSummaryProps> = ({ job }) => {
  return (
    <Fragment>
      <div>
        <FormLabel>Työpaikka</FormLabel>
        <ResultsP>{job.els_nimi}</ResultsP>
      </div>
      <div>
        <FormLabel>Toimiala</FormLabel>
        <ResultsP>{job.tyosuhdetoimiala.value}</ResultsP>
      </div>
      <div>
        <FormLabel>Onko tämä päätoiminen työpaikkasi?</FormLabel>
        <ResultsP>{job.els_paatoimi ? 'Kyllä' : 'Ei'}</ResultsP>
      </div>
      <div>
        <FormLabel>Ammattinimike</FormLabel>
        <ResultsP>{job.ammattinimike.els_koodinnimi}</ResultsP>
      </div>
      <div>
        <FormLabel>Aloituspäivämäärä</FormLabel>
        <ResultsP>{job.els_alkupvm}</ResultsP>
      </div>
      {job.els_loppupvm && (
        <div>
          <FormLabel>Päättymispäivämäärä</FormLabel>
          <ResultsP>{job.els_loppupvm}</ResultsP>
        </div>
      )}
    </Fragment>
  )
}

export default JobSummary
