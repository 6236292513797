import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#00624b'
    },
    secondary: {
      main: '#edf2ff'
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      }
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
        MenuProps: {
          PaperProps: { sx: { maxHeight: 400 } }
        }
      }
    }
  }
})

export default theme
