import React from 'react'

interface FormButtonContainerProps {
  align: 'left' | 'right'
  children: React.ReactNode
}

const FormButtonContainer: React.FC<FormButtonContainerProps> = ({ children, align }) => (
  <div style={{ width: '100%', textAlign: align }}>{children}</div>
)

export default FormButtonContainer
