import * as yup from 'yup'

const phoneRegEx = /\+[\d -]+/

const personalInfoSchema = yup
  .object({
    lastname: yup.string().required(),
    firstname: yup.string().max(50).required(),
    els_hetu: yup.string().required(),
    nickname: yup.string(),
    terhikkireknro: yup
      .string()
      .matches(/^[0-9]*$/, 'Terhikkinumeron tulee sisältää vain numeroita')
      .length(11, 'Terhikkinumeron tulee olla 11 numeroa pitkä'),
    address: yup.object().shape({
      line1: yup.string().max(250).required('Täytä puuttuva kenttä'),
      line2: yup.string().max(250).optional(),
      line3: yup.string().max(250).optional(),
      postalcode: yup.string().max(20).required('Täytä puuttuva kenttä'),
      country: yup.string().required('Täytä puuttuva kenttä'),
      city: yup.string().max(80).required('Täytä puuttuva kenttä'),
      els_postikieli: yup.string().required('Täytä puuttuva kenttä')
    }),
    mobilephone: yup
      .string()
      .max(50)
      .matches(phoneRegEx, 'Puhelinnumeron tulee olla kansainvälisessä muodossa (+358)')
      .required(),
    emailaddress1: yup
      .string()
      .max(100)
      .email('Syötä validi sähköpostiosoite')
      .required('Täytä puuttuva kenttä'),
    cursus: yup.object().shape({
      els_oppilaitos: yup.string().required('Täytä puuttuva kenttä'),
      els_aloitusvuosi: yup.string().required('Täytä puuttuva kenttä'),
      els_lukukausi: yup.string().required('Täytä puuttuva kenttä')
    }),
    school: yup.string()
  })
  .required()

const employment1Schema = yup.object().shape({
  employment: yup
    .object()
    .shape({
      els_tyosuhdesektori: yup.string().required(),
      els_soteorganisaatio: yup.string().required('Täytä kaikki kentät ja valitse työpaikka')
    })
    .required()
})

const employment2Schema = yup.object().shape({
  employment: yup.object().shape({
    tyosuhdetoimiala: yup.string().required('Täytä puuttuva kenttä'),
    els_paatoimi: yup.string().required('Täytä puuttuva kenttä'),
    ammattinimike: yup.string().required('Täytä puuttuva kenttä'),
    els_alkupvm: yup.string().required('Täytä puuttuva kenttä')
  })
})

/** Empty schema since Summary page doesn't have any fields */
const summarySchema = yup.object().shape({})

export const studentFormSchema = [
  // page 0
  personalInfoSchema,
  // page 1
  employment1Schema,
  // page 2
  employment2Schema,
  // page 3
  summarySchema
]
