import { AddCircle, RemoveCircle } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import React from 'react'
import { ContainerHeaderH1 } from '../../components/styled/uiComponents'

interface Props {
  isOpen: boolean
  setIsOpen: (v: boolean) => void
  title: string
}

const MobileTitle: React.FC<Props> = ({ isOpen, setIsOpen, title }) => (
  <>
    <Grid item xs={10}>
      <ContainerHeaderH1>{title}</ContainerHeaderH1>
    </Grid>
    <Grid item xs={2}>
      <IconButton size='large' onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <RemoveCircle color='primary' /> : <AddCircle color='primary' />}
      </IconButton>
    </Grid>
  </>
)

export default MobileTitle
