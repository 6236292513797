import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, LinearProgress, Typography } from '@mui/material'
import axios from 'axios'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../context/AuthProvider'
import FormStepper from '../../../components/FormStepper'
import PageHeader from '../../../components/PageHeader'
import { LaakariButton, ShadowContainer } from '../../../components/styled/uiComponents'
import { MetadataContext, UserContext } from '../../../context'
import { buildApiUrl, parseFormData } from '../../../utils/utils'
import FormSentConfirmation from '../FormSentConfirmation'
import EmploymentForm from '../MembershipForm/EmploymentForm'
import EmploymentForm2 from '../MembershipForm/EmploymentForm2'
import { isSessionInvalidError } from '../../../utils/error'
import Summary from './Summary'
import PersonalInfo from './PersonalInfo'
import { studentFormSchema } from './schema'

const StudentForm = () => {
  const [page, setPage] = useState(0)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const { user } = useContext(UserContext)
  const { metaData } = useContext(MetadataContext)
  const { bearerToken, evaluateToken, handleLogout } = useContext(AuthContext)

  const navigate = useNavigate()

  useEffect(() => {
    evaluateToken()
  }, [])

  const currentValidationSchema = studentFormSchema[page]
  const methods = useForm<any>({
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(currentValidationSchema),
    defaultValues: {
      formType: 'STUDENT',
      organization: {
        name: 'Suomen Lääkäriliitto'
      },
      els_hetu: user?.els_hetu,
      birthdate: user?.birthdate,
      lastname: user?.lastname,
      firstname: user?.firstname
    }
  })

  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
    trigger
  } = methods

  const jobValue = getValues('employment.els_soteorganisaatio')

  useEffect(() => {
    /** go to first employment page if no employment is selected */
    if (page === 2 && !jobValue) {
      setPage(1)
    }
  }, [page])

  const pageProps = { register, user, errors, metaData, getValues, control }

  const handleNext = async (e: any) => {
    e.preventDefault()
    const isStepValid = await trigger()
    if (isStepValid) {
      setPage(prevActivePage => prevActivePage + 1)
    }
  }

  const handleBack = (e: any) => {
    e.preventDefault()
    setPage(prevActivePage => prevActivePage - 1)
  }

  const onSubmit = async (data: any) => {
    if (data.cursus && !data.cursus.els_oppilaitos) {
      console.log('Removing empty cursus')
      data.cursus = undefined
    }
    try {
      const parsedData = parseFormData(data)
      const url = buildApiUrl('membership-application')
      await axios.post(url, parsedData, {
        headers: { 'x-tietoni-token': `Bearer ${bearerToken}` }
      })
      setFormSubmitted(true)
    } catch (error) {
      console.log('error when submitting form', error)
      if (isSessionInvalidError(error)) {
        toast.error('Istunto on päättynyt. Kirjaudu uudelleen sisään.')
        handleLogout(() => navigate('/'))
      }
    }
  }

  console.log('errors: ', errors)

  return metaData && user ? (
    <Fragment>
      {formSubmitted ? (
        <FormSentConfirmation formName='Ilmoittaudu lääketieteen opiskelijaksi' />
      ) : (
        <Fragment>
          <PageHeader />
          <div style={{ padding: '3vh' }}>
            <Typography variant='h3' color='primary' sx={{ paddingBottom: '3vh' }}>
              Ilmoittaudu lääketieteen opiskelijaksi
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <FormStepper activeStep={page} stepsCount={4} />
                {page === 3 && <p>Olet lähettämässä lomaketta seuraavilla tiedoilla: </p>}
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <ShadowContainer>
                      {page === 0 && <PersonalInfo watch={watch} {...pageProps} />}
                      {page === 1 && (
                        <EmploymentForm
                          skipEmployment={() => {
                            setValue('employment', undefined)
                            setPage(3)
                          }}
                          watch={watch}
                          {...pageProps}
                        />
                      )}
                      {page === 2 && jobValue && (
                        <EmploymentForm2
                          jobValue={jobValue}
                          removeJob={() => {
                            setValue('employment', undefined)
                            setPage(page - 1)
                          }}
                          {...pageProps}
                        />
                      )}
                      {page === 3 && <Summary formState={getValues()} />}
                    </ShadowContainer>

                    <Grid
                      container
                      spacing={3}
                      direction='row'
                      wrap='wrap-reverse'
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ paddingTop: '3vh' }}>
                      <Grid item xs={12} md={3}>
                        <LaakariButton variant='outlined' onClick={handleBack}>
                          Takaisin
                        </LaakariButton>
                      </Grid>
                      {page !== 3 ? (
                        <Grid item xs={12} md={3}>
                          <LaakariButton onClick={handleNext}>Jatka</LaakariButton>
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={3}>
                          <LaakariButton disabled={isSubmitting} type='submit'>
                            Lähetä
                          </LaakariButton>
                        </Grid>
                      )}
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Grid>
          </div>
        </Fragment>
      )}
    </Fragment>
  ) : (
    <LinearProgress />
  )
}

export default StudentForm
