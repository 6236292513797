import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import {
  MenuItem,
  Select,
  Grid,
  CircularProgress,
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Switch,
  DialogActions,
  Stack
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import axios from 'axios'
import PageHeader from '../../components/PageHeader'
import { buildApiUrl, parseDate } from '../../utils/utils'

import { getAdminTokenLocalStorage } from '../../utils/localStorage'
import ExportCsvButton from '../../components/ExportCsvButton'
import { LaakariButton } from '../../components/styled/uiComponents'
import { membershipStartingTimes } from '../../utils/stringConstants'
import { useAdminAuth } from './PersonnelAuthProvider'
import StudentApplicationDetails from './DetailViews/StudentApplicationDetails'
import MemberApplicationDetails from './DetailViews/MemberApplicationDetails'
import ResignationApplicationDetails from './DetailViews/ResignationApplicationDetails'
import DiscountApplicationDetails from './DetailViews/DiscountApplicationDetails'
import StampOrderDetails from './DetailViews/StampOrderDetails'

const applicationTypes = [
  { name: 'STUDENT', displayName: 'Opiskelijailmoittautuminen' },
  { name: 'MEMBER', displayName: 'Jäsenyyshakemus' },
  { name: 'DISCOUNT', displayName: 'Jäsenmaksualennus' },
  { name: 'RESIGNATION', displayName: 'Eroilmoitus' },
  { name: 'STAMP_ORDER', displayName: 'Leimasintilaus' }
]

const shortenDiscountName = (name: string) => {
  const prefix = 'Suomen Lääkäriliitto - '
  if (name && name.startsWith(prefix)) {
    return name.substring(prefix.length)
  } else {
    return name
  }
}

const AdminView = () => {
  const [applicationType, setApplicationType] = useState<string>(applicationTypes[0].name)
  const [activeDataSet, setActiveDataSet] = useState<any>()
  const [detailedApplication, setDetailedApplication] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const [selectedIds, setSelectedIds] = useState<any>([])
  const [filter, setFilter] = useState('LÄHETETTY')

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const [calendarOrderDisabled, setCalendarOrderDisabled] = useState<boolean>(false)

  const bearerToken = getAdminTokenLocalStorage()
  const navigate = useNavigate()
  const adminAuth = useAdminAuth()

  const showCsvButton =
    (activeDataSet && applicationType === 'DISCOUNT') ||
    (activeDataSet && applicationType === 'STUDENT') ||
    (activeDataSet && applicationType === 'RESIGNATION') ||
    (activeDataSet && applicationType === 'MEMBER') ||
    (activeDataSet && applicationType === 'STAMP_ORDER')

  const handleChangeFilter = (e: any) => {
    setFilter(e.target.value)
  }

  const fetchApplications = async (applicationType: string, filter?: string) => {
    try {
      setLoading(true)
      axios
        .get(buildApiUrl('personnel-list-applications'), {
          params: {
            applicationType: applicationType,
            state: filter
          },
          headers: { 'x-tietoni-token': `Bearer ${bearerToken}` }
        })
        .then(res => {
          setLoading(false)
          setActiveDataSet(res.data)
        })
    } catch (error) {
      console.log('error: ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchApplications(applicationType, filter)
  }, [filter])

  const processApplications = async (
    applicationIds: string | string[],
    actionType: string,
    applicationType: string
  ) => {
    try {
      axios
        .put(
          buildApiUrl('personnel-process-applications'),
          {
            applicationIds: applicationIds,
            applicationType: applicationType,
            operation: actionType
          },
          { headers: { 'x-tietoni-token': `Bearer ${bearerToken}` } }
        )
        .then(res => {
          console.log(res)
          fetchApplications(applicationType, filter)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const getMerchStatus = async () => {
    try {
      axios
        .get(buildApiUrl('get-merchandice-status'), {
          headers: { 'x-tietoni-token': `Bearer ${bearerToken}` }
        })
        .then(res => {
          console.log('response: ', res)
        })
    } catch (error) {
      console.log('error: ', error)
    }
  }

  useEffect(() => {
    getMerchStatus()
  }, [])

  const handleClickOpen = (rowId: string) => {
    setDetailedApplication(activeDataSet.filter((application: any) => rowId === application.id)[0])
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChangeApplicationType = (e: any) => {
    setApplicationType(e.target.value)
    fetchApplications(e.target.value, filter)
  }

  const handleOpenConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen)
  }

  const handleDisableCalendarOrders = () => {
    setCalendarOrderDisabled(calendarOrderDisabled => !calendarOrderDisabled)
    setConfirmModalOpen(false)
  }

  const handleCancelOpenConfirmModal = () => {
    setCalendarOrderDisabled(calendarOrderDisabled)
    setConfirmModalOpen(false)
  }

  useEffect(() => {
    fetchApplications('STUDENT', filter)
  }, [])

  const renderConfirmModal = () => (
    <Dialog open={confirmModalOpen} onClose={handleOpenConfirmModal}>
      <DialogTitle>Keskeytä kalenteritilaukset</DialogTitle>
      <DialogContent>Haluatko varmasti keskeyttää kalenteritilaukset?</DialogContent>
      <DialogActions>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Button variant='outlined' onClick={handleCancelOpenConfirmModal}>
            Peruuta
          </Button>
          <Button onClick={handleDisableCalendarOrders}>Kyllä</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )

  const renderInfo = () => (
    <Dialog fullWidth={true} maxWidth='xl' open={open} onClose={handleClose}>
      <DialogTitle>{`Hakemuksen id: ${detailedApplication?.id}`}</DialogTitle>
      <DialogContent>
        {applicationType === 'STUDENT' && (
          <StudentApplicationDetails
            selectedApplication={detailedApplication}
            applicationType='Opiskelijailmoittautuminen'
          />
        )}
        {applicationType === 'MEMBER' && (
          <MemberApplicationDetails
            selectedApplication={detailedApplication}
            applicationType='Jäsenyyshakemus'
          />
        )}
        {applicationType === 'RESIGNATION' && (
          <ResignationApplicationDetails
            selectedApplication={detailedApplication}
            applicationType='Eroilmoitus'
          />
        )}
        {applicationType === 'DISCOUNT' && (
          <DiscountApplicationDetails
            selectedApplication={detailedApplication}
            applicationType='Jäsenmaksualennus'
          />
        )}
        {applicationType === 'STAMP_ORDER' && (
          <StampOrderDetails
            selectedApplication={detailedApplication}
            applicationType='Leimasintilaus'
          />
        )}
      </DialogContent>
    </Dialog>
  )

  const renderDetailsButton = (params: any) => {
    return (
      <strong>
        <Button
          variant='contained'
          color='primary'
          size='small'
          style={{ marginLeft: 16 }}
          onClick={() => {
            handleClickOpen(params.row.id)
          }}>
          Tiedot
        </Button>
      </strong>
    )
  }

  const studentColumns = [
    { field: 'firstname', headerName: 'Etunimi', width: 250 },
    { field: 'lastname', headerName: 'Sukunimi', width: 250 },
    { field: 'formType', headerName: 'Hakemustyyppi', width: 250 },
    { field: 'submitted', headerName: 'Hakemus lähetetty', width: 150 },
    { field: 'status', headerName: 'Status', width: 250 },
    { field: 'statusChanged', headerName: 'Status päivitetty', width: 150 },
    {
      field: 'info',
      headerName: 'Lisätietoja',
      width: 150,
      renderCell: renderDetailsButton
    }
  ]

  const memberColumns = [
    { field: 'firstname', headerName: 'Etunimi', width: 250 },
    { field: 'lastname', headerName: 'Sukunimi', width: 250 },
    { field: 'formType', headerName: 'Hakemustyyppi', width: 250 },
    { field: 'submitted', headerName: 'Hakemus lähetetty', width: 150 },
    { field: 'membership_starts', headerName: 'Jäsenyys alkaa', width: 350 },
    { field: 'status', headerName: 'Status', width: 130 },
    { field: 'statusChanged', headerName: 'Status päivitetty', width: 130 },
    {
      field: 'info',
      headerName: 'Lisätietoja',
      width: 150,
      renderCell: renderDetailsButton
    }
  ]

  const discountColumns = [
    { field: 'firstname', headerName: 'Etunimet', width: 250 },
    { field: 'lastname', headerName: 'Sukunimi', width: 250 },
    { field: 'alennuksennimi', headerName: 'Alennus', width: 250 },
    { field: 'alennusalkaen', headerName: 'Alennus alkaen', width: 250 },
    { field: 'attachments', headerName: 'Liitteet', width: 250 },
    { field: 'submitted', headerName: 'Hakemus lähetetty', width: 150 },
    { field: 'status', headerName: 'Status', width: 250 },
    { field: 'statusChanged', headerName: 'Status päivitetty', width: 150 },
    {
      field: 'info',
      headerName: 'Lisätietoja',
      width: 150,
      renderCell: renderDetailsButton
    }
  ]

  const resignationColumns = [
    { field: 'firstname', headerName: 'Etunimet', width: 250 },
    { field: 'lastname', headerName: 'Sukunimi', width: 250 },
    { field: 'timeOfResignation', headerName: 'Eroamispäivä', width: 180 },
    { field: 'membership', headerName: 'Jäsenyys', width: 150 },
    { field: 'submitted', headerName: 'Hakemus lähetetty', width: 130 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'statusChanged', headerName: 'Status päivitetty', width: 150 },
    {
      field: 'info',
      headerName: 'Lisätietoja',
      width: 150,
      renderCell: renderDetailsButton
    }
  ]

  const stampOrderColumns = [
    { field: 'name', headerName: 'Nimi', width: 250 },
    { field: 'title', headerName: 'Titteli', width: 250 },
    { field: 'isPaid', headerName: 'Maksullinen', width: 150 },
    { field: 'submitted', headerName: 'Hakemus lähetetty', width: 130 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'statusChanged', headerName: 'Status päivitetty', width: 150 },
    {
      field: 'info',
      headerName: 'Lisätietoja',
      width: 150,
      renderCell: renderDetailsButton
    }
  ]

  const getStudentRows = () =>
    activeDataSet?.map((application: any) => ({
      id: application.id,
      status: application.data.state,
      firstname: application.data.formData.firstname,
      lastname: application.data.formData.lastname,
      formType: application.data.formData.formType,
      submitted: parseDate(application.data.submitted),
      statusChanged: parseDate(application.data.stateChanged)
    }))

  const getMemberRows = () =>
    activeDataSet?.map((application: any) => {
      const membershipStarts = membershipStartingTimes[application.data.formData.membership_starts]

      return {
        id: application.id,
        status: application.data.state,
        firstname: application.data.formData.firstname,
        lastname: application.data.formData.lastname,
        formType: application.data.formData.formType,
        submitted: parseDate(application.data.submitted),
        membership_starts: membershipStarts,
        statusChanged: parseDate(application.data.stateChanged)
      }
    })

  const getDiscountRows = () =>
    activeDataSet?.map((application: any) => ({
      id: application.id,
      status: application.data.state,
      lastname: application.data.aapeliData?.lastname,
      alennuksennimi:
        shortenDiscountName(application.data.formData.els_alennus?.els_alennuksennimi) ||
        'Tilinumeron ilmoitus',
      alennusalkaen: application.data.formData.els_alennusalkaen,
      attachments: application.data.formData.attachments?.length,
      submitted: parseDate(application.data.submitted),
      statusChanged: parseDate(application.data.stateChanged)
    }))

  const getResignationRows = () =>
    activeDataSet?.map((application: any) => ({
      id: application.id,
      status: application.data.state,
      lastname: application.data.aapeliData?.lastname,
      firstname: application.data.aapeliData?.firstname,
      timeOfResignation: application.data.formData.timeOfResignation,
      membership: application.data.formData.membership?.els_jasentyyppi,
      submitted: parseDate(application.data.submitted),
      statusChanged: parseDate(application.data.stateChanged)
    }))

  const getStampOrderRows = () =>
    activeDataSet?.map((application: any) => ({
      id: application.id,
      status: application.data.state,
      name: application.data?.formData?.deliveryAddress?.name,
      title: application.data?.formData?.stampInfo?.title,
      isPaid: application.data?.formData?.isPaid,
      submitted: parseDate(application.data.submitted),
      statusChanged: parseDate(application.data.stateChanged)
    }))

  const renderDataGrid = () => {
    switch (applicationType) {
      case 'STUDENT':
        return (
          <DataGrid
            checkboxSelection
            onSelectionModelChange={rows => {
              setSelectedIds(rows)
            }}
            rows={getStudentRows()}
            columns={studentColumns}
          />
        )
      case 'MEMBER':
        return (
          <DataGrid
            checkboxSelection
            onSelectionModelChange={rows => {
              setSelectedIds(rows)
            }}
            rows={getMemberRows()}
            columns={memberColumns}
          />
        )
      case 'DISCOUNT':
        return (
          <DataGrid
            checkboxSelection
            onSelectionModelChange={rows => {
              setSelectedIds(rows)
            }}
            rows={getDiscountRows()}
            columns={discountColumns}
          />
        )
      case 'RESIGNATION':
        return (
          <DataGrid
            checkboxSelection
            onSelectionModelChange={rows => {
              setSelectedIds(rows)
            }}
            rows={getResignationRows()}
            columns={resignationColumns}
          />
        )
      case 'STAMP_ORDER':
        return (
          <DataGrid
            checkboxSelection
            onSelectionModelChange={rows => {
              setSelectedIds(rows)
            }}
            rows={getStampOrderRows()}
            columns={stampOrderColumns}
          />
        )
    }
  }

  const renderTableContainer = () => {
    return loading ? (
      <CircularProgress />
    ) : (
      <div style={{ height: 500, width: '100%' }}>{renderDataGrid()}</div>
    )
  }

  return (
    <>
      {renderConfirmModal()}
      {renderInfo()}
      <PageHeader />
      <Grid container direction='column' sx={{ padding: '3vh' }}>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            <FormLabel>Hakemustyyppi: </FormLabel>
            <Select
              disabled={loading}
              value={applicationType}
              label='Hakemustyyppi'
              onChange={handleChangeApplicationType}>
              <MenuItem value=''>--Valitse--</MenuItem>
              {applicationTypes.map(type => (
                <MenuItem key={type.name} value={type.name}>
                  {type.displayName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <h1>Toimihenkilö UI</h1>
          </Grid>
          <Grid item>
            <LaakariButton
              onClick={() => {
                adminAuth.handleAdminLogout(() => navigate('/adminLogin'))
              }}>
              ADMIN LOGOUT
            </LaakariButton>
          </Grid>
        </Grid>
        <Grid container direction='row' sx={{ paddingBottom: '3vh' }}>
          <Grid item xs={6}>
            {!loading
              ? showCsvButton && (
                  <ExportCsvButton
                    applicationType={applicationType}
                    selectedDataSet={activeDataSet}
                  />
                )
              : null}

            {!loading && (
              <FormControl sx={{ paddingLeft: '3vh' }}>
                <FormLabel>Hakemuksen tila</FormLabel>
                <RadioGroup
                  row
                  name='radio-buttons-group'
                  onChange={handleChangeFilter}
                  value={filter}>
                  <FormControlLabel value='LÄHETETTY' control={<Radio />} label='Lähetetty' />
                  <FormControlLabel value='KÄSITTELYSSÄ' control={<Radio />} label='Käsittelyssä' />
                  <FormControlLabel value='HYVÄKSYTTY' control={<Radio />} label='Hyväksytty' />
                  <FormControlLabel value='HYLÄTTY' control={<Radio />} label='Hylätty' />
                  <FormControlLabel value='' control={<Radio />} label='Hae kaikki' />
                </RadioGroup>
              </FormControl>
            )}
          </Grid>

          <Grid item sx={{ paddingTop: '3vh' }} xs={6}>
            <Button
              sx={{ marginRight: '3vh' }}
              color='success'
              disabled={selectedIds?.length === 0 || loading}
              onClick={() => processApplications(selectedIds, 'APPROVE', applicationType)}>
              Hyväksy valitut
            </Button>
            <Button
              sx={{ marginRight: '3vh' }}
              color='error'
              disabled={selectedIds?.length === 0 || loading}
              onClick={() => processApplications(selectedIds, 'REJECT', applicationType)}>
              Hylkää valitut
            </Button>
            <Button
              sx={{ marginRight: '3vh' }}
              disabled={selectedIds?.length === 0 || loading}
              onClick={() => processApplications(selectedIds, 'PROCESS', applicationType)}>
              Siirrä käsittelyyn
            </Button>
          </Grid>
        </Grid>
        <Grid item>{activeDataSet && renderTableContainer()}</Grid>
        <Grid item>
          <FormControlLabel
            control={<Switch value={calendarOrderDisabled} />}
            label={calendarOrderDisabled ? 'Kalenteritilaus sallittu' : 'Kalenteritilaus estetty'}
            onChange={handleOpenConfirmModal}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default AdminView
