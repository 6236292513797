import React, { useContext } from 'react'
import { Grid, Typography } from '@mui/material'

import { useForm } from 'react-hook-form'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import {
  FormLabel,
  FormSubmitButton,
  LaakariButton,
  MainHeaderH1,
  ResultsP,
  ShadowContainer
} from '../../components/styled/uiComponents'

import { buildApiUrl, parseFormData } from '../../utils/utils'

import { AuthContext } from '../../context/AuthProvider'
import { StudentFormContext } from '../../context/StudentFormContext'

const StudentFormResults = () => {
  const { formState } = useContext(StudentFormContext)
  const { bearerToken } = useContext(AuthContext)
  const navigate = useNavigate()

  const { register, handleSubmit } = useForm({
    defaultValues: formState
  })

  const onSubmit = () => {
    const parsedData = parseFormData(formState)
    const url = buildApiUrl('membership-application')

    axios({
      method: 'post',
      url: url,
      data: parsedData,
      headers: {
        'x-tietoni-token': 'Bearer ' + bearerToken
      }
    }).then(res => {
      console.log('res: ', res)
      navigate('/formSent')
    })
  }

  const handleNavigateBack = () => {
    navigate(-1)
  }

  return (
    <>
      <PageHeader />
      <div style={{ padding: '3vh' }}>
        <MainHeaderH1>Ilmoittaudu lääketieteen opiskelijaksi</MainHeaderH1>
        <Grid container>
          <Grid item xs={12}>
            <p>Olet lähettämässä lomaketta seuraavilla tiedoilla</p>
          </Grid>
          <Grid item xs={8}>
            {formState ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <ShadowContainer>
                  <Typography variant='h3' color='primary'>
                    Henkilötiedot
                  </Typography>
                  <div>
                    <FormLabel>Sukunimi</FormLabel>
                    <ResultsP {...register('lastname')}>{formState.lastname}</ResultsP>
                  </div>
                  <div>
                    <FormLabel>Etunimet</FormLabel>
                    <ResultsP {...register('firstname')}>{formState.firstname}</ResultsP>
                  </div>
                  {formState.nickName && (
                    <div>
                      <FormLabel>Kutsumanimi</FormLabel>
                      <ResultsP {...register('nickname')}>{formState.nickname}</ResultsP>
                    </div>
                  )}
                  <div>
                    <FormLabel>Henkilötunnus</FormLabel>
                    <ResultsP {...register('els_hetu')}>{formState.els_hetu}</ResultsP>
                  </div>
                  {formState.terhikkireknro && (
                    <div>
                      <FormLabel>
                        Sosiaali- ja terveysalan lupa- ja valvontaviraston (Valvira)
                        rekisteröintinumero (Terhikkinumero)
                      </FormLabel>
                      <ResultsP {...register('terhikkireknro')}>
                        {formState.terhikkireknro}
                      </ResultsP>
                    </div>
                  )}
                  <div style={{ borderTop: '1px dotted black' }}>
                    <Typography variant='h3' color='primary'>
                      Yhteystiedot
                    </Typography>
                  </div>
                  <div>
                    <FormLabel>Postiosoite</FormLabel>
                    <ResultsP {...register('line1')}>{formState.address.line1}</ResultsP>
                  </div>
                  {formState.address.line2 && (
                    <div>
                      <FormLabel>Postiosoitteen rivi 2</FormLabel>
                      <ResultsP {...register('line2')}>{formState.address.line2}</ResultsP>
                    </div>
                  )}
                  {formState.address.line3 && (
                    <div>
                      <FormLabel>Postiosoitteen rivi 3</FormLabel>
                      <ResultsP {...register('line3')}>{formState.address.line3}</ResultsP>
                    </div>
                  )}
                  <div>
                    <FormLabel>Postinumero</FormLabel>
                    <ResultsP {...register('postalcode')}>{formState.address.postalcode}</ResultsP>
                  </div>
                  <div>
                    <FormLabel>Postitoimipaikka</FormLabel>
                    <ResultsP {...register('city')}>{formState.address.city}</ResultsP>
                  </div>
                  <div>
                    <FormLabel>Postituskieli</FormLabel>
                    <ResultsP {...register('els_postikieli')}>
                      {JSON.parse(formState.address.els_postikieli).value}
                    </ResultsP>
                  </div>
                  <div>
                    <FormLabel>Maa</FormLabel>
                    <ResultsP {...register('address.country.els_koodinnimi')}>
                      {JSON.parse(formState.address.country.els_koodinnimi).els_koodinnimi}
                    </ResultsP>
                  </div>
                  <div>
                    <FormLabel>Matkapuhelinnumero</FormLabel>
                    <ResultsP {...register('mobilephone')}>{formState.mobilephone}</ResultsP>
                  </div>
                  <div>
                    <FormLabel>Sähköpostiosoite</FormLabel>
                    <ResultsP {...register('emailaddress1')}>{formState.emailaddress1}</ResultsP>
                  </div>
                  <div>
                    <FormLabel>Markkinointilupa</FormLabel>
                    <ResultsP {...register('address.markkinoinninesto')}>
                      {JSON.parse(formState.address.markkinoinninesto).value}
                    </ResultsP>
                  </div>

                  <div style={{ borderTop: '1px dotted black' }}>
                    <Typography variant='h3' color='primary'>
                      Opiskelutiedot
                    </Typography>
                  </div>
                  {formState.cursus.school && (
                    <div>
                      <FormLabel>Oppilaitos</FormLabel>
                      <ResultsP {...register('cursus.school')}>{formState.cursus.school}</ResultsP>
                    </div>
                  )}
                  <div>
                    <FormLabel>Aloitusvuosi</FormLabel>
                    <ResultsP {...register('cursus.els_aloitusvuosi')}>
                      {formState.cursus.els_aloitusvuosi}
                    </ResultsP>
                  </div>
                  <div>
                    <FormLabel>Lukukausi</FormLabel>
                    <ResultsP {...register('cursus.els_lukukausi')}>
                      {JSON.parse(formState.cursus.els_lukukausi).value}
                    </ResultsP>
                  </div>

                  <div style={{ borderTop: '1px dotted black' }}>
                    <Typography variant='h3' color='primary'>
                      Työpaikkatiedot
                    </Typography>
                  </div>
                  {!formState.employment && (
                    <div>
                      <ResultsP>Työpaikkatietojen syöttö on ohitettu</ResultsP>
                    </div>
                  )}

                  {formState.employment ? (
                    <>
                      <div>
                        <FormLabel>Työpaikka</FormLabel>
                        <ResultsP {...register('employment.els_soteorganisaatio.els_longname')}>
                          {formState.employment.els_soteorganisaatio.els_longname}
                        </ResultsP>
                      </div>
                      <div>
                        <FormLabel>Toimiala</FormLabel>
                        <ResultsP {...register('employment.tyosuhdetoimiala')}>
                          {JSON.parse(formState.employment.tyosuhdetoimiala).value}
                        </ResultsP>
                      </div>
                      <div>
                        <FormLabel>Onko tämä päätoiminen työpaikkasi?</FormLabel>
                        <ResultsP {...register('employment.els_paatoimi')}>
                          {formState.employment.els_paatoimi}
                        </ResultsP>
                      </div>
                      <div>
                        <FormLabel>Ammattinimike</FormLabel>
                        <ResultsP {...register('employment.ammattinimike.els_koodinnimi')}>
                          {
                            JSON.parse(formState.employment.ammattinimike.els_koodinnimi)
                              .els_koodinnimi
                          }
                        </ResultsP>
                      </div>
                      <div>
                        <FormLabel>Aloituspäivämäärä</FormLabel>
                        <ResultsP {...register('employment.els_alkupvm')}>
                          {formState.employment.els_alkupvm}
                        </ResultsP>
                      </div>
                    </>
                  ) : null}
                </ShadowContainer>
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  style={{ paddingTop: '3vh' }}>
                  <Grid item>
                    <LaakariButton variant='outlined' onClick={handleNavigateBack}>
                      Takaisin
                    </LaakariButton>
                  </Grid>
                  <Grid item>
                    <FormSubmitButton type='submit' value='Lähetä' />
                  </Grid>
                </Grid>
              </form>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default StudentFormResults
