import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'

import { buildApiUrl } from '../../utils/utils'

import {
  getAdminTokenLocalStorage,
  putAdminTokenLocalStorage,
  removeAdminTokenLocalStorage
} from '../../utils/localStorage'

interface AuthContextType {
  sessionId: string | null
  bearerToken: string | null | undefined
  handleAdminLogin: (callback: VoidFunction) => void
  handleAdminLogout: (callback: VoidFunction) => void
}

export const PersonnelAuthContext = React.createContext<AuthContextType>(null!)

export const useAdminAuth = () => {
  return useContext(PersonnelAuthContext)
}

const redirectUri = process.env.REACT_APP_LOGIN_REDIRECT_URL
  ? new URL('/personnel/login-success', process.env.REACT_APP_LOGIN_REDIRECT_URL).toString()
  : undefined

const PersonnelAuthProvider = ({ children }: { children: React.ReactNode }) => {
  const adminTokenLocalStorage = getAdminTokenLocalStorage()
  const [sessionId, setSessionId] = useState<string | null>(null)
  const [bearerToken, setBearerToken] = useState<string | null>(adminTokenLocalStorage)

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const handleAdminLogin = (callback: VoidFunction) => {
    axios
      .post(buildApiUrl('personnelSessions'), {
        redirects: redirectUri ? { redirectUri } : undefined
      })
      .then(response => {
        window.location.replace(response.data.Location)
      })
    callback()
  }

  const handleAdminLogout = (callback: VoidFunction) => {
    setBearerToken(null)
    callback()
  }

  const handleFetchBearerToken = async (sessionId: string) => {
    try {
      const response = await axios.get(buildApiUrl('personnelSessions', sessionId), {
        params: {
          redirectUri
        }
      })
      setBearerToken(response.data.token)
      putAdminTokenLocalStorage(response.data.token)
      navigate('/admin')
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const evaluateToken = async () => {
      try {
        if (bearerToken) {
          const response = await axios.get(buildApiUrl('personnelSessions/evaluateToken'), {
            headers: { 'x-tietoni-token': `Bearer ${bearerToken}` }
          })
          console.log('evaluate admin token: ', JSON.stringify(response, null, 2))
        }
      } catch (error) {
        console.log('Bearer token not valid, removing from storage')
        removeAdminTokenLocalStorage()
        setBearerToken(null)
        console.log(error)
      }
    }
    evaluateToken()
  }, [])

  useEffect(() => {
    setSessionId(searchParams.get('code'))
  }, [])

  useEffect(() => {
    if (sessionId) {
      handleFetchBearerToken(sessionId)
    }
  }, [sessionId])

  const value = { sessionId, bearerToken, handleAdminLogin, handleAdminLogout }
  return <PersonnelAuthContext.Provider value={value}>{children}</PersonnelAuthContext.Provider>
}

export default PersonnelAuthProvider
