import { Typography } from '@mui/material'
import React, { Fragment } from 'react'

import { ResultsP, FormLabel } from '../../../components/styled/uiComponents'
import { parseDate } from '../../../utils/utils'

interface Props {
  selectedApplication: any
  applicationType: string
}

const DiscountApplicationDetails: React.FC<Props> = ({ selectedApplication, applicationType }) => {
  const application = {
    ...selectedApplication.data.formData,
    ...selectedApplication.data.aapeliData,
    handlingEvents: selectedApplication.data.handlingEvents,
    identity: selectedApplication.data.personIdentity
  }
  return (
    <>
      <div>
        <Typography variant='h4' color='primary'>
          Hakemuksen tyyppi: {applicationType}
        </Typography>
      </div>
      <div>
        <FormLabel>Sukunimi</FormLabel>
        <ResultsP>{application.lastname}</ResultsP>
      </div>

      <div>
        <FormLabel>Etunimet</FormLabel>
        <ResultsP>{application.firstname}</ResultsP>
      </div>

      <div>
        <FormLabel>Henkilönumero</FormLabel>
        <ResultsP>{application.identity?.els_henkilonumero}</ResultsP>
      </div>

      <div>
        <FormLabel>Alennus</FormLabel>
        <ResultsP>{application.els_alennus?.els_alennuksennimi || 'Tilinumeron ilmoitus'}</ResultsP>
      </div>

      <div>
        <FormLabel>Alennus alkaen</FormLabel>
        <ResultsP>{application.els_alennusalkaen}</ResultsP>
      </div>

      {application.memberSinceYear && (
        <div>
          <FormLabel>Toisen liiton jäsen vuodesta</FormLabel>
          <ResultsP>{application.memberSinceYear}</ResultsP>
        </div>
      )}

      {application.attachments && (
        <div>
          <FormLabel style={{ paddingBottom: '1vh' }}>Liitteet</FormLabel>
          {application.attachments.map((attachment: any, i: number) => (
            <div key={i} style={{ paddingTop: '2vh' }}>
              <ResultsP>
                <b>Liite {i + 1}</b>
              </ResultsP>
              <ResultsP>{attachment.originalName}</ResultsP>
              <a href={attachment.url} download>
                Klikaa ladataksesi liite
              </a>
            </div>
          ))}
        </div>
      )}

      {application.bankAccount && (
        <div>
          <FormLabel>Tilinumero</FormLabel>
          <ResultsP>{application.bankAccount}</ResultsP>
        </div>
      )}

      <div>
        <FormLabel>Hakemus lähetetty</FormLabel>
        <ResultsP>{parseDate(selectedApplication.data.submitted)}</ResultsP>
      </div>

      {application.handlingEvents && (
        <div>
          <FormLabel>Käsittelytapahtumat</FormLabel>
          {application.handlingEvents.map((handlingEvent: any, i: number) => (
            <div key={i}>
              <ResultsP>
                {handlingEvent.timestamp} - {handlingEvent.operation?.name} -{' '}
                {handlingEvent.handler?.name}
              </ResultsP>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default DiscountApplicationDetails
