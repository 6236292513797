import React, { useContext, useEffect, useState } from 'react'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { format, add } from 'date-fns'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { UserContext } from '../context'
import { isCurrentProperMemberOfFMA } from '../utils/membershipChecks'

const MemberCard = () => {
  const [hasLoadedCard, setHasLoadedCard] = useState<boolean>(false)
  const { user } = useContext(UserContext)

  useEffect(() => {
    const generatePdf = () => {
      console.log('ajetaan')
      const cardAsPdf = new jsPDF('landscape', 'mm', 'a4')
      const element = document.getElementById('testId') as HTMLElement
      console.log(element)
      if (element) {
        html2canvas(element).then(canvas => {
          const imageData = canvas.toDataURL('image/png')
          cardAsPdf.addImage(imageData, 'PNG', 15, 15, 0, 0)
          cardAsPdf.save('jasenkortti.pdf')
          setHasLoadedCard(true)
        })
      }
    }

    if (!hasLoadedCard) {
      generatePdf()
    }
  }, [user])

  if (!user) {
    return <CircularProgress />
  } else {
    const { qualifications } = user

    const originalMembershipDate = new Date(user?.memberships[0]?.els_jasenalkupvm)
    const membershipValidUntil = add(originalMembershipDate, { years: 1 })
    const formattedValidDate = format(membershipValidUntil, 'yyyy')
    const birthDate = new Date(user?.birthdate)
    const formattedBirthday = format(birthDate, 'dd.MM.yyyy')
    const mailingLanguage = user?.postikieli?.value
    const notCurrentlyStudent = isCurrentProperMemberOfFMA(user)
    const specialities = qualifications?.filter(
      (q: any) => q?.tutkinnon_ylaluokka === 'Erikoislääkärin tutkinto'
    )

    console.log('isCurr: ', notCurrentlyStudent)

    return (
      <div id='testId'>
        <Stack
          sx={{
            height: '39.8rem',
            width: '63.3rem',
            backgroundImage: notCurrentlyStudent
              ? `url(${process.env.PUBLIC_URL}/jasenkortti.png)`
              : `url(${process.env.PUBLIC_URL}/jasenkortti-opiskelija.png)`
          }}>
          <Stack direction='row'>
            <Stack spacing={2} sx={{ pl: '3rem', pt: '15rem' }}>
              <Typography fontSize={26}>{`Jäsennumero: ${user?.els_henkilonumero}`}</Typography>
              <Typography fontSize={26}>{`Nimi: ${user?.firstname} ${user?.lastname}`}</Typography>
              <Typography fontSize={26}>{`Syntymäpäivä: ${formattedBirthday}`}</Typography>
              <Typography
                fontSize={26}>{`Jäsenyys voimassa 03/${formattedValidDate} asti`}</Typography>
            </Stack>
            <Stack spacing={2} sx={{ pl: '10rem', pt: '8rem' }}>
              <Typography fontSize={26}>
                {mailingLanguage === 'Suomi' ? 'Erikoisalat:' : 'Specialities:'}
              </Typography>
              {specialities?.map((speciality: any, i: number) => (
                <Typography key={i} fontSize={26}>
                  {speciality.tutkinto_laillistus}
                </Typography>
              ))}
            </Stack>
          </Stack>
          <Stack spacing={2} sx={{ pl: '3rem', pt: '2rem' }}>
            {mailingLanguage === 'Suomi' ? (
              <>
                <Typography fontSize={20}>
                  Tällä kortilla voit todistaa olevasi lääkäri ja Lääkäriliiton jäsen.
                </Typography>
                <Typography fontSize={20}>
                  The cardholder is a medical doctor and a member of the Finnish Medical
                  Association.
                </Typography>
              </>
            ) : (
              <>
                <Typography fontSize={20}>
                  Med detta kort kan du bevisa att du är studentmedlem i Läkarförbundet.
                </Typography>
                <Typography fontSize={20}>
                  The cardholder is a medical doctor and a member of the Finnish Medical
                  Association.
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </div>
    )
  }
}

export default MemberCard
