import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'

interface Props {
  open: boolean
  closeDialog: () => void
  title: string
  content: React.ReactNode
  fullScreen: boolean
}

const JobDialog: React.FC<Props> = ({ open, closeDialog, title, content, fullScreen }) => {
  return (
    <Dialog maxWidth='lg' fullWidth fullScreen={fullScreen} open={open} onClose={closeDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
    </Dialog>
  )
}

export default JobDialog
