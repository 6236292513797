import { FormControlLabel, Radio, RadioGroup, Typography, Grid } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import { ErrorMessage } from '../../../components/styled/uiComponents'
import { CommonFormSectionProps } from '../../../types'

const FIELD_NAME = 'membership_starts'

const MembershipStartsField: React.FC<CommonFormSectionProps> = ({ control, errors }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h5' color='primary'>
          Jäsenyyden aloitusajankohta
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={FIELD_NAME}
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup onChange={e => onChange(e.target.value)} value={value}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControlLabel
                    value='WHEN_APPROVED'
                    control={<Radio />}
                    label='Haluan, että jäsenyyteni astuu voimaan välittömästi hallituksen hyväksymisen jälkeen'
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    value='BEGINNING_OF_CALENDAR_YEAR'
                    control={<Radio />}
                    label='Haluan, että jäsenyyteni astuu voimaan seuraavan kalenterivuoden alusta'
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          )}
        />
      </Grid>
      <ErrorMessage>{errors.membership_starts?.message}</ErrorMessage>
    </Grid>
  )
}
export default MembershipStartsField
