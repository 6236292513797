import { Box, Step, StepLabel, Stepper } from '@mui/material'
import React from 'react'

interface Props {
  activeStep: number
  stepsCount: number
}

const FormStepper: React.FC<Props> = ({ activeStep, stepsCount }) => {
  return (
    <Box sx={{ width: '100%', marginBottom: 8 }}>
      <Stepper sx={{ color: '#00624b' }} activeStep={activeStep} alternativeLabel>
        {new Array(stepsCount).fill('').map((val, index) => (
          <Step sx={{ color: '#00624b' }} key={index}>
            <StepLabel></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
export default FormStepper
