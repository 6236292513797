import React, { useState, useEffect } from 'react'
import { FormLabel, Grid, Typography } from '@mui/material'
import { Control, UseFormRegister } from 'react-hook-form'

import { format, subYears } from 'date-fns'
import { ErrorMessage, FormInput } from '../../../../components/styled/uiComponents'

interface Props {
  register: UseFormRegister<any>
  control: Control<any, any>
  getValues: (name?: string) => any
  errors?: any
  metaData?: any
  handleChangeDescription: (description: string) => void
}

const PentionClass: React.FC<Props> = ({ register, handleChangeDescription, errors }) => {
  const [startDate, setStartDate] = useState<string>('')

  useEffect(() => {
    handleChangeDescription('Jäljennös eläkepäätöksestä tai työeläkekortista.')
  }, [])

  const handleChangeStartDate = (e: any) => {
    setStartDate(e.target.value)
  }

  const oneYearAgo = format(subYears(new Date(), 1), 'yyyy-MM-dd')

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography variant='h4' color='primary'>
            Jään eläkkeelle tai osa-aikaeläkkeelle, luovun äänioikeudesta (75 %)
          </Typography>
          <p>
            Olen vanhuuseläkkeellä tai jään vanhuuseläkkeelle kuluvana vuonna. Alennus myönnetään
            hakemusta seuraavan kalenterivuoden alusta. Alennus on voimassa 85 vuoden ikään asti,
            jonka jälkeen myönnetään automaattisesti (ilman hakemusta) maksuvapautus seuraavan
            kalenterivuoden alusta. 75 % alennus perusjäsenmaksusta, muut jäsenoikeudet, mutta ei
            vaalikelpoisuutta/äänioikeutta Lääkäriliiton vaaleissa.
          </p>
        </Grid>

        <Grid item xs={12} md={6} style={{ paddingTop: '3vh', paddingBottom: '2vh' }}>
          <FormLabel>Alkaen</FormLabel>
          <FormInput
            {...register('els_alennusalkaen')}
            type='date'
            min={oneYearAgo}
            onChange={handleChangeStartDate}
            value={startDate}
          />
          <ErrorMessage>{errors?.els_alennusalkaen?.message}</ErrorMessage>
        </Grid>

        <div style={{ borderTop: '1px dotted black', paddingTop: '3vh', paddingBottom: '3vh' }}>
          <Typography variant='h6' color='primary'>
            Liitteet
          </Typography>
          <p>
            Jäljennös eläkepäätöksestä tai työeläkekortista. Voit skannata tai ottaa valokuvan
            asiakirjasta.
          </p>
          <input {...register('attachments')} type='file' accept='.pdf,.jpg,.png' multiple />
          <ErrorMessage>{errors?.attachments?.message}</ErrorMessage>
        </div>

        <div style={{ borderTop: '1px dotted black', paddingTop: '3vh' }}>
          <Typography variant='h6' color='primary'>
            Jäsenmaksun mahdollinen palautus
          </Typography>
          <Grid item xs={12} md={6}>
            <FormLabel>
              Tilinumero jäsenmaksun mahdollista palautusta varten (IBAN muodossa)
            </FormLabel>
            <FormInput {...register('bankAccount')} />
            <ErrorMessage>{errors?.bankAccount?.message}</ErrorMessage>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

export default PentionClass
