import React, { useState, useEffect } from 'react'
import { FormLabel, Grid, Typography } from '@mui/material'

import { Control, UseFormRegister } from 'react-hook-form'

import { format } from 'date-fns'
import { ErrorMessage, FormInput } from '../../../../components/styled/uiComponents'

interface Props {
  register: UseFormRegister<any>
  control: Control<any, any>
  getValues: (name?: string) => any
  errors?: any
  metaData?: any
  handleChangeDescription: (description: string) => void
}

const Hoitovapaa: React.FC<Props> = ({ register, handleChangeDescription, errors }) => {
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')

  useEffect(() => {
    handleChangeDescription(
      'Jäljennös hoitovapaapäätöksestä, kotihoidon tai omaishoidon tukipäätöksestä.'
    )
  }, [])

  const handleChangeStartDate = (e: any) => {
    setStartDate(e.target.value)
  }

  const handleChangeEndDate = (e: any) => {
    setEndDate(e.target.value)
  }

  const today = format(new Date(), 'yyyy-MM-d')

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h4' color='primary'>
          Olen hoitovapaalla tai sairaan omaisen hoidon vuoksi poissa työelämästä
        </Typography>
        <p>
          Olen hoitovapaalla tai sairaan omaisen hoidon vuoksi pois työelämästä yhtäjaksoisesti
          vähintään puoli vuotta. Hoitovapaata ei voi yhdistää äitiys-, isyys- ja
          vanhempainvapaakauteen. 50 % alennus perusjäsenmaksusta.
        </p>

        <Grid item xs={12} md={6} style={{ paddingTop: '3vh', paddingBottom: '2vh' }}>
          <FormLabel>Alkaen</FormLabel>
          <FormInput
            {...register('els_alennusalkaen')}
            type='date'
            min={today}
            onChange={handleChangeStartDate}
            value={startDate}
          />
          <ErrorMessage>{errors?.els_alennusalkaen?.message}</ErrorMessage>
        </Grid>

        <Grid item xs={12} md={6} style={{ paddingBottom: '3vh' }}>
          <FormLabel>Päättyen</FormLabel>
          <FormInput
            {...register('els_alennuspaattyen')}
            type='date'
            min={today}
            onChange={handleChangeEndDate}
            value={endDate}
          />
          <ErrorMessage>{errors?.els_alennuspaattyen?.message}</ErrorMessage>
        </Grid>

        <div style={{ borderTop: '1px dotted black', paddingTop: '3vh', paddingBottom: '3vh' }}>
          <Typography variant='h6' color='primary'>
            Liitteet
          </Typography>
          <p>
            Jäljennös hoitovapaapäätöksestä, kotihoidon tai omaishoidon tukipäätöksestä. Voit
            skannata tai ottaa valokuvan asiakirjasta.
          </p>
          <input {...register('attachments')} type='file' accept='.pdf,.jpg,.png' multiple />
          <ErrorMessage>{errors?.attacments?.message}</ErrorMessage>
        </div>

        <div style={{ borderTop: '1px dotted black', paddingTop: '3vh' }}>
          <Typography variant='h6' color='primary'>
            Jäsenmaksun mahdollinen palautus
          </Typography>
          <Grid item xs={12} md={6}>
            <FormLabel>
              Tilinumero jäsenmaksun mahdollista palautusta varten (IBAN muodossa)
            </FormLabel>
            <FormInput {...register('bankAccount')} />
            <ErrorMessage>{errors?.bankAccount?.message}</ErrorMessage>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

export default Hoitovapaa
