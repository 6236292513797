import React, { useState, Fragment, useContext } from 'react'
import {
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  Typography,
  CircularProgress
} from '@mui/material'
import axios from 'axios'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { useNavigate } from 'react-router-dom'
import FormStepper from '../../../../components/FormStepper'
import PageHeader from '../../../../components/PageHeader'
import {
  LaakariButton,
  ShadowContainer,
  SubHeaderH2
} from '../../../../components/styled/uiComponents'

import { buildApiUrl } from '../../../../utils/utils'
import { MembershipContext, MetadataContext, UserContext } from '../../../../context'
import { AuthContext } from '../../../../context/AuthProvider'

import FormSentConfirmation from '../../FormSentConfirmation'
import { isSessionInvalidError } from '../../../../utils/error'
import TemporaryExpat from './TemporaryExpat'
import PermanentExpat from './PermanentExpat'
import PentionForthcoming from './PentionForthcoming'
import PentionClass from './PentionClass'
import Hoitovapaa from './Hoitovapaa'
import SicknessPay from './SicknessPay'
import AnotherUnionMember from './AnotherUnionMember'
import DisabilityPention from './DisablilityPention'
import Unemployment from './Unemployment'
import ParentalLeave from './ParentalLeave'
import ArmyDiscount from './ArmyDiscount'
import GrantResearcher from './GrantResearcher'

import { getSchema, allSchemas } from './discountFormSchema'

interface Props {
  returnToActionChoice: (formChoice: string | null) => void
}

interface Discount {
  name: string
  id: number
  hasFileUpload: boolean
  hasEndDateField: boolean
  displayName?: string
  aapeliName?: string
}

const discounts = [
  {
    name: 'Asun ulkomailla tilapäisesti',
    aapeliName:
      'Suomen Lääkäriliitto - 50 % Ulkomailla vähintään vuoden yhtäjaksoisesti asuva, ei tee lääkärin työtä Suomessa ko. aikana',
    id: 0,
    hasFileUpload: false,
    hasEndDateField: true
  },
  {
    name: 'Asun ulkomailla pysyvästi',
    aapeliName: 'Suomen Lääkäriliitto - 50 % Ulkomailla pysyvästi asuva',
    id: 1,
    hasFileUpload: false,
    hasEndDateField: false
  },
  {
    name: 'Eläkkeelle jäävä varsinainen jäsen (50 %)',
    aapeliName: 'Suomen Lääkäriliitto - 50 % Eläkkeellä oleva',
    displayName: 'Jään eläkkeelle, säilytän täydet jäsenoikeudet (50 %)',
    id: 2,
    hasFileUpload: true,
    hasEndDateField: false
  },
  {
    name: 'Siirtyminen eläkejäsenmaksuluokkaan (75 %)',
    aapeliName: 'Suomen Lääkäriliitto - 75 % Eläkeläismaksuluokka, ei vaalikelpoisuutta',
    displayName: 'Jään eläkkeelle, luovun äänioikeudesta (75 %)',
    id: 3,
    hasFileUpload: true,
    hasEndDateField: false
  },
  {
    name: 'Hoitovapaa tai sairaan omaisen hoito',
    aapeliName:
      'Suomen Lääkäriliitto - 50 % Hoitovapaalla tai sairaan omaisen hoidon vuoksi työelämästä vähintään puoli vuotta poissa oleva',
    displayName: 'Olen hoitovapaalla tai sairaan omaisen hoidon vuoksi poissa työelämästä',
    id: 4,
    hasFileUpload: true,
    hasEndDateField: true
  },
  {
    name: 'Sairauspäiväraha',
    aapeliName: 'Suomen Lääkäriliitto - 50 % Sairauspäivärahalla vähintään puoli vuotta oleva',
    displayName: 'Olen sairauspäivärahalla',
    id: 5,
    hasFileUpload: true,
    hasEndDateField: true
  },
  {
    name: 'Toisen liiton jäsen',
    displayName: 'Alennusperusteena oleva liitto',
    id: 6,
    hasFileUpload: false,
    hasEndDateField: false
  },
  {
    name: 'Työkyvyttömyyseläke tai kuntoutustuki',
    aapeliName:
      'Suomen Lääkäriliitto - 50 % Työkyvyttömyyseläkkeellä tai kuntoutustuella vähintään puoli vuotta oleva',
    displayName: 'Työkyvyttömyyseläke tai kuntoutustuki',
    id: 7,
    hasFileUpload: true,
    hasEndDateField: true
  },
  {
    name: 'Työttömyys',
    aapeliName: 'Suomen Lääkäriliitto - 50 % Työttömänä vähintään puoli vuotta oleva',
    displayName: 'Olen työtön',
    id: 8,
    hasFileUpload: true,
    hasEndDateField: true
  },
  {
    name: 'Vanhempainvapaa',
    aapeliName:
      'Suomen Lääkäriliitto - 50 % Äitiys-, isyys- ja/tai vanhempainvapaalla vähintään puoli vuotta oleva',
    displayName: 'Olen vanhempainvapaalla',
    id: 9,
    hasFileUpload: true,
    hasEndDateField: true
  },
  {
    name: 'Varusmies- tai siviilipalvelus',
    aapeliName:
      'Suomen Lääkäriliitto - 100 % Varusmies- tai siviilipalvelua väh. puoli vuotta suorittava',
    displayName: 'Suoritan varusmies- tai siviilipalvelusta',
    id: 10,
    hasFileUpload: true,
    hasEndDateField: false
  },
  {
    name: 'Apurahatutkija',
    aapeliName:
      'Suomen Lääkäriliitto - 50 % Apurahatutkijana työskentelevä yhtäjaksoisesti vähintään puoli vuotta, ei muita tuloja',
    displayName: 'Olen apurahalla työskentelevä tutkija',
    id: 11,
    hasFileUpload: true,
    hasEndDateField: true
  }
]

const DiscountForm: React.FC<Props> = ({ returnToActionChoice }) => {
  const [activeStep, setActiveStep] = useState<number>(0)
  const [selectedDiscount, setSelectedDiscount] = useState<Discount | null | undefined>()
  const [formSent, setFormSent] = useState<boolean>(false)
  const [attachmentDescription, setAttachmentDescription] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const navigate = useNavigate()
  const { metaData } = useContext(MetadataContext)
  const { bearerToken } = useContext(AuthContext)
  const { contextMembership } = useContext(MembershipContext)
  const { user } = useContext(UserContext)

  const selectedSchemaId: number = getSchema(
    selectedDiscount?.hasEndDateField || false,
    selectedDiscount?.hasFileUpload || false,
    selectedDiscount?.id
  )

  const {
    register,
    control,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({
    resolver: yupResolver(allSchemas[selectedSchemaId]),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      els_alennus: {
        els_alennuksennimi: selectedDiscount
      },
      els_hetu: user?.els_hetu,
      els_alennusalkaen: '',
      els_alennuspaattyen: '',
      bankAccount: ''
    }
  })

  const handleChangeDescription = (description: string) => {
    setAttachmentDescription(description)
  }

  const pageProps = { register, errors, metaData, getValues, control, handleChangeDescription }

  const handleChangeDiscount = (e: any) => {
    const discount = discounts.filter(discount => discount.name === e.target.value)[0]

    setSelectedDiscount(discount)
  }

  const handleContinueForm = () => {
    setActiveStep(activeStep + 1)
    setPage(1)
  }

  const fetchSignedUrl = async (fileType: string) => {
    return axios
      .get(buildApiUrl('upload-url'), {
        params: {
          contentType: fileType
        },
        headers: { 'x-tietoni-token': `Bearer ${bearerToken}` }
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error)
      })
  }

  const onSubmit = async (data: any) => {
    try {
      setLoading(true)

      const newAttachmentList: any[] = []

      const payload = { ...data, attachments: newAttachmentList }

      if (data.attachments) {
        const files = data.attachments

        for (const file of files) {
          const fileType = file.type
          const signedUrlInfo = await fetchSignedUrl(fileType)
          const storageName = signedUrlInfo.destFileName

          await axios.put(signedUrlInfo.signedUrl, file, {
            headers: {
              'Content-Type': fileType
            }
          })

          newAttachmentList.push({
            storageName: storageName,
            originalName: file.name,
            description: attachmentDescription
          })
        }
      }

      data.els_alennus.els_alennuksennimi =
        selectedSchemaId !== 4 && selectedDiscount ? selectedDiscount.aapeliName : null

      axios
        .post(buildApiUrl('discount-application'), payload, {
          headers: { 'x-tietoni-token': `Bearer ${bearerToken}` }
        })
        .then(res => {
          console.log('response: ', res)
          setLoading(false)
          setFormSent(true)
        })
    } catch (error) {
      setLoading(false)
      console.log('error when submitting form', error)
      if (isSessionInvalidError(error)) {
        navigate('/')
        alert('Istunto päättynyt. Ole hyvä ja kirjaudu sisään.')
      }
    }
  }

  const renderForm = () => {
    switch (selectedDiscount?.name) {
      case 'Asun ulkomailla tilapäisesti':
        return <TemporaryExpat {...pageProps} />
      case 'Asun ulkomailla pysyvästi':
        return <PermanentExpat {...pageProps} />
      case 'Eläkkeelle jäävä varsinainen jäsen (50 %)':
        return <PentionForthcoming {...pageProps} />
      case 'Siirtyminen eläkejäsenmaksuluokkaan (75 %)':
        return <PentionClass {...pageProps} />
      case 'Hoitovapaa tai sairaan omaisen hoito':
        return <Hoitovapaa {...pageProps} />
      case 'Sairauspäiväraha':
        return <SicknessPay {...pageProps} />
      case 'Toisen liiton jäsen':
        return <AnotherUnionMember {...pageProps} />
      case 'Työkyvyttömyyseläke tai kuntoutustuki':
        return <DisabilityPention {...pageProps} />
      case 'Työttömyys':
        return <Unemployment {...pageProps} />
      case 'Vanhempainvapaa':
        return <ParentalLeave {...pageProps} />
      case 'Varusmies- tai siviilipalvelus':
        return <ArmyDiscount {...pageProps} />
      case 'Apurahatutkija':
        return <GrantResearcher {...pageProps} />
      default:
        break
    }
  }

  const returnToDiscountChoice = () => {
    setActiveStep(0)
    setPage(0)
  }

  return (
    <>
      {formSent ? (
        <FormSentConfirmation formName='Hae alennusta jäsenmaksuun' subUrl='#membership' />
      ) : (
        <>
          <PageHeader />
          <div style={{ padding: '3vh' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Typography variant='h3' color='primary'>
                  Hae alennusta jäsenmaksuun
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <FormStepper activeStep={activeStep} stepsCount={2} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ShadowContainer>
                    <SubHeaderH2>Jäsenyys</SubHeaderH2>
                    <b>{`${contextMembership?.yhdistys_name} - ${contextMembership?.els_jasentyyppi}`}</b>

                    {page === 1 ? (
                      <span style={{ paddingTop: '3vh' }}>{renderForm()}</span>
                    ) : (
                      <RadioGroup
                        value={selectedDiscount?.name}
                        style={{
                          marginTop: '3vh'
                        }}>
                        {discounts.map((discount: Discount) => (
                          <FormControlLabel
                            {...register('els_alennus.els_alennuksennimi')}
                            control={<Radio />}
                            key={discount.id}
                            value={discount.name}
                            label={discount.displayName || discount.name}
                            onChange={handleChangeDiscount}
                            sx={{ paddingBottom: '1vh' }}
                          />
                        ))}
                      </RadioGroup>
                    )}
                  </ShadowContainer>
                  <Grid
                    container
                    spacing={3}
                    direction='row'
                    wrap='wrap-reverse'
                    alignItems='center'
                    justifyContent='space-between'
                    sx={{ paddingTop: '3vh' }}>
                    <Grid item xs={12} md={3} style={{ paddingTop: '3vh' }}>
                      {page === 0 ? (
                        <LaakariButton
                          variant='outlined'
                          onClick={() => returnToActionChoice(null)}>
                          Takaisin
                        </LaakariButton>
                      ) : (
                        <LaakariButton variant='outlined' onClick={returnToDiscountChoice}>
                          Takaisin
                        </LaakariButton>
                      )}
                    </Grid>
                    {page === 0 && (
                      <Grid item xs={12} md={3} style={{ paddingTop: '3vh', textAlign: 'right' }}>
                        <LaakariButton onClick={handleContinueForm} disabled={!selectedDiscount}>
                          Jatka
                        </LaakariButton>
                      </Grid>
                    )}
                    {page === 1 && (
                      <Grid item xs={12} md={3} style={{ paddingTop: '3vh', textAlign: 'right' }}>
                        <LaakariButton type='submit' disabled={loading}>
                          {loading ? <CircularProgress size={24.5} /> : 'Lähetä'}
                        </LaakariButton>
                      </Grid>
                    )}
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  )
}

export default DiscountForm
