const getTokenLocalStorage = () => localStorage.getItem('token')

const putTokenLocalStorage = (id: string) => localStorage.setItem('token', id)

const removeTokenLocalStorage = () => localStorage.removeItem('token')

const getAdminTokenLocalStorage = () => localStorage.getItem('adminToken')

const putAdminTokenLocalStorage = (id: string) => localStorage.setItem('adminToken', id)

const removeAdminTokenLocalStorage = () => localStorage.removeItem('adminToken')

export {
  getTokenLocalStorage,
  putTokenLocalStorage,
  removeTokenLocalStorage,
  getAdminTokenLocalStorage,
  putAdminTokenLocalStorage,
  removeAdminTokenLocalStorage
}
