import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'

import { ToastContainer } from 'react-toastify'
import {
  EmploymentFormContextProvider,
  MembershipContextProvider,
  MetadataContextProvider,
  StudentFormContextProvider,
  UserContextProvider
} from './context'
import theme from './theme'
import 'react-toastify/dist/ReactToastify.css'
import AppRouter from './router'
import AuthProvider from './context/AuthProvider'
import PersonnelAuthProvider from './containers/adminView/PersonnelAuthProvider'

const App = () => {
  return (
    <>
      <Router>
        <ThemeProvider theme={theme}>
          <ToastContainer />
          <UserContextProvider>
            <MetadataContextProvider>
              <MembershipContextProvider>
                <StudentFormContextProvider>
                  <EmploymentFormContextProvider>
                    <PersonnelAuthProvider>
                      <AuthProvider>
                        <AppRouter />
                      </AuthProvider>
                    </PersonnelAuthProvider>
                  </EmploymentFormContextProvider>
                </StudentFormContextProvider>
              </MembershipContextProvider>
            </MetadataContextProvider>
          </UserContextProvider>
        </ThemeProvider>
      </Router>
    </>
  )
}

export default App
