import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import PageHeader from '../../../components/PageHeader'
import { LaakariButton, ShadowContainer } from '../../../components/styled/uiComponents'
import { MembershipContext } from '../../../context'
import ChooseOrgAndAction from './ChooseOrgAndAction'
import DiscountForm from './DiscountForm'
import ResignationForm from './ResignationForm'
import PaymentReturnForm from './PaymentReturnForm'

const MembershipAction: React.FC = () => {
  const [selectedAction, setSelectedAction] = useState<string>('')
  const [formSelection, setFormSelection] = useState<string | null>('')

  const { contextMembership } = useContext(MembershipContext)

  const navigate = useNavigate()

  useEffect(() => {
    if (!contextMembership) {
      navigate('/')
    }
  }, [])

  const handleChooseForm = (formChoice: string | null) => {
    setFormSelection(formChoice)
  }

  const handleChangeAction = (e: any) => {
    setSelectedAction(e.target.value)
  }

  return (
    <>
      {formSelection === '0' && <DiscountForm returnToActionChoice={handleChooseForm} />}
      {formSelection === '1' && <PaymentReturnForm returnToActionChoice={handleChooseForm} />}
      {formSelection === '2' && (
        <ResignationForm returnToActionChoice={handleChooseForm} selectedOrg={contextMembership} />
      )}
      {!formSelection && (
        <>
          <PageHeader />
          <div style={{ padding: '3vh' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Typography variant='h3' color='primary'>
                  Tee jäsenyysmuutos
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <ShadowContainer>
                  {!formSelection && (
                    <ChooseOrgAndAction
                      handleChangeAction={handleChangeAction}
                      selectedAction={selectedAction}
                      selectedOrg={contextMembership}
                    />
                  )}
                </ShadowContainer>
                <Grid
                  container
                  spacing={3}
                  direction='row'
                  wrap='wrap-reverse'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{ paddingTop: '3vh' }}>
                  <Grid item xs={12} md={3} style={{ paddingTop: '3vh' }}>
                    <LaakariButton variant='outlined' onClick={() => navigate('/#membership')}>
                      Palaa etusivulle
                    </LaakariButton>
                  </Grid>
                  {contextMembership && (
                    <Grid item xs={12} md={3} style={{ paddingTop: '3vh', textAlign: 'right' }}>
                      <LaakariButton
                        onClick={() => handleChooseForm(selectedAction)}
                        disabled={!selectedAction}>
                        Jatka
                      </LaakariButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  )
}

export default MembershipAction
