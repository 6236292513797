import * as yup from 'yup'

const missingMsg = 'Täytä puuttuva kenttä'
const maxStampName = 25
const maxTitle = 35

const schema = yup.object({
  stampInfo: yup
    .object()
    .shape({
      name: yup
        .string()
        .required(missingMsg)
        .max(maxStampName, `Pituus korkeintaan ${maxStampName} merkkiä`),
      title: yup
        .string()
        .required(missingMsg)
        .max(maxTitle, `Pituus korkeintaan ${maxTitle} merkkiä`),
      terhikki: yup
        .string()
        .required(missingMsg)
        .matches(/^\d{11}$/, 'Yksilöintitunnuksen tulee muodostua 11 numerosta.'),
      quantity: yup.number().integer().positive().required(missingMsg),
      type: yup.string().oneOf(['printer20', 'goldring']).required(missingMsg)
    })
    .required(),
  deliveryAddress: yup
    .object()
    .shape({
      name: yup.string().required(missingMsg).max(250, 'Nimi on liian pitkä'),
      streetAddress: yup.string().required(missingMsg).max(250, 'Osoite on liian pitkä'),
      postalCode: yup.string().max(20).required(missingMsg),
      city: yup.string().max(80).required(missingMsg),
      country: yup.string().required(missingMsg),
      mobilePhone: yup
        .string()
        .matches(/\+[\d -]+/, 'Puhelinnumeron tulee olla kansainvälisessä muodossa (+358)')
        .required(missingMsg)
    })
    .required()
})

export default schema
