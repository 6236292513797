import { Typography } from '@mui/material'
import React, { Fragment } from 'react'

import { ResultsP, FormLabel } from '../../../components/styled/uiComponents'
import { parseDate } from '../../../utils/utils'

interface Props {
  selectedApplication: any
  applicationType: string
}

const StampOrderDetails: React.FC<Props> = ({ selectedApplication, applicationType }) => {
  const application = {
    ...selectedApplication.data,
    ...selectedApplication.data.formData,
    handlingEvents: selectedApplication.data.handlingEvents,
    identity: selectedApplication.data.personIdentity
  }
  // nimi;osoite;postinumero;postitoimipaikka;matkapuhelin;leimasinnimi;titteli;yksilointitunnus;kpl;tyyppi;maksullinen;pvm
  return (
    <>
      <div>
        <Typography variant='h4' color='primary'>
          Hakemuksen tyyppi: {applicationType}
        </Typography>
      </div>
      <div>
        <FormLabel>Nimi</FormLabel>
        <ResultsP>{application.deliveryAddress?.name}</ResultsP>
      </div>
      <div>
        <FormLabel>Osoite</FormLabel>
        <ResultsP>{application.deliveryAddress?.streetAddress}</ResultsP>
      </div>
      <div>
        <FormLabel>Postinumero</FormLabel>
        <ResultsP>{application.deliveryAddress?.postalCode}</ResultsP>
      </div>
      <div>
        <FormLabel>Postitoimipaikka</FormLabel>
        <ResultsP>{application.deliveryAddress?.city}</ResultsP>
      </div>
      <div>
        <FormLabel>Matkapuhelin</FormLabel>
        <ResultsP>{application.deliveryAddress?.mobilePhone}</ResultsP>
      </div>

      <div>
        <FormLabel>Leimasinnimi</FormLabel>
        <ResultsP>{application.stampInfo?.name}</ResultsP>
      </div>
      <div>
        <FormLabel>Titteli</FormLabel>
        <ResultsP>{application.stampInfo?.title}</ResultsP>
      </div>
      <div>
        <FormLabel>Yksilöintitunnus</FormLabel>
        <ResultsP>{application.stampInfo?.terhikki}</ResultsP>
      </div>
      <div>
        <FormLabel>Kpl</FormLabel>
        <ResultsP>{application.stampInfo?.quantity}</ResultsP>
      </div>
      <div>
        <FormLabel>Tyyppi</FormLabel>
        <ResultsP>{application.stampInfo?.type}</ResultsP>
      </div>
      <div>
        <FormLabel>Maksullinen</FormLabel>
        <ResultsP>{application.isPaid ? 'Kyllä' : 'Ei'}</ResultsP>
      </div>
      <div>
        <FormLabel>Pvm</FormLabel>
        <ResultsP>{parseDate(application.submitted, 'yyyyMMdd')}</ResultsP>
      </div>
    </>
  )
}

export default StampOrderDetails
