import axios from 'axios'
import React, { createContext, useEffect, useState } from 'react'
import { buildApiUrl } from '../utils/utils'

export const MetadataContext = createContext<any>(null)

const MetadataContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [metaData, setMetaData] = useState<any>()

  const fetchMetaData = () => {
    axios.get(buildApiUrl('metadata')).then(res => {
      setMetaData(res.data)
    })
  }

  useEffect(() => {
    fetchMetaData()
  }, [])

  const value = { metaData }

  return <MetadataContext.Provider value={value}>{children}</MetadataContext.Provider>
}

export default MetadataContextProvider
