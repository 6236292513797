import React, { createContext, useState } from 'react'

export const EmploymentFormContext = createContext<any>(null)

const EmploymentFormContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [contextJob, setContextJob] = useState<any>()
  const [contextSector, setContextSector] = useState<any>()

  const value = { contextJob, setContextJob, contextSector, setContextSector }
  return <EmploymentFormContext.Provider value={value}>{children}</EmploymentFormContext.Provider>
}

export default EmploymentFormContextProvider
