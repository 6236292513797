import React, { createContext, useState } from 'react'

export const StudentFormContext = createContext<any>(null)

const StudentFormContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [step, setStep] = useState<number>(0)
  const [formState, setFormState] = useState<any>()

  const updatePayloadState = (state: any, payload: any) => {
    setFormState((state: any) => ({
      ...state,
      ...payload
    }))

    return {
      ...state,
      ...payload
    }
  }

  const value = { step, setStep, formState, setFormState, updatePayloadState }
  return <StudentFormContext.Provider value={value}>{children}</StudentFormContext.Provider>
}

export default StudentFormContextProvider
