import React, { useContext, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import {
  Autocomplete,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'

import InfoIcon from '@mui/icons-material/InfoOutlined'

import {
  FormLabel,
  ShadowContainer,
  LaakariButton,
  ErrorMessage,
  FormInput,
  FormInputWide
} from '../../../components/styled/uiComponents'

import PageHeader from '../../../components/PageHeader'
import FormSentConfirmation from '../FormSentConfirmation'

import { ICountry, ISemester, MarketingBlock } from '../../../types'

import { AuthContext } from '../../../context/AuthProvider'
import { buildApiUrl, parseFormData, yearList } from '../../../utils/utils'
import { MetadataContext, UserContext } from '../../../context'
import { personalInfoSchema } from './schema'

const UpdatePersonalInfo = () => {
  const [formSent, setFormSent] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [cursusPresent, setCursusPresent] = useState<boolean>(false)

  const { metaData } = useContext(MetadataContext)
  const { bearerToken, evaluateToken } = useContext(AuthContext)
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const defaultValues = {
    els_hetu: user?.els_hetu,
    contactInfo: {
      firstname: user?.firstname,
      lastname: user?.lastname,
      nickname: user?.nickname,
      els_postikieli: user?.els_postikieli
        ? JSON.stringify({
            attributevalue: user?.postikieli?.attributevalue,
            value: user?.postikieli?.value
          })
        : '',
      mobilephone: user?.mobilephone,
      emailaddress1: user?.emailaddress1,
      els_markkinoininesto: JSON.stringify({
        attributevalue: user?.markkinoinninesto?.attributevalue,
        value: user?.markkinoinninesto?.value
      }),
      line1: user?.address1_line1,
      line2: user?.address1_line2,
      line3: user?.address1_line3,
      postalcode: user?.address1_postalcode,
      city: user?.address1_city,
      country: user?.country
        ? JSON.stringify({
            els_koodiid: user?.address1_country?.els_koodiid,
            els_koodinnimi: user?.address1_country?.els_koodinnimi
          })
        : '',
      cursus: {
        els_oppilaitos: user?.els_cursus?.els_oppilaitos
          ? JSON.stringify({
              els_koodiid: user.els_cursus.els_oppilaitos.els_koodiid,
              els_koodinnimi: user.els_cursus.els_oppilaitos.els_koodinnimi
            })
          : '',
        els_aloitusvuosi: user?.els_cursus?.els_aloitusvuosi,
        els_lukukausi: user?.els_cursus?.els_lukukausi
          ? JSON.stringify({
              attributevalue: user.els_cursus.els_lukukausi.attributevalue,
              value: user.els_cursus.els_lukukausi.value
            })
          : ''
      }
    }
  }

  interface PersonalInfoFormParams {
    els_hetu: string
    contactInfo: {
      firstname: string
      lastname: string
      nickname: string
      els_postikieli: string
      mobilephone: string
      emailaddress1: string
      els_markkinoininesto: string
      line1: string
      line2?: string
      line3?: string
      postalcode: string
      city: string
      country: string
      cursus: {
        els_oppilaitos: string | undefined | null
        els_aloitusvuosi: string | undefined | null
        els_lukukausi: string | undefined | null
      }
    }
  }
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors }
  } = useForm<PersonalInfoFormParams>({
    resolver: yupResolver(personalInfoSchema)
  })

  useEffect(() => {
    if (user) {
      reset(defaultValues)
    }
  }, [user])

  useEffect(() => {
    evaluateToken()
  }, [])

  const onSubmit = (data: any) => {
    if (data.contactInfo.cursus && !data.contactInfo.cursus.els_oppilaitos) {
      console.log('Removing empty cursus')
      data.contactInfo.cursus = undefined
    }
    try {
      setIsLoading(true)
      const parsedData = parseFormData(data)
      console.log('parsedData: ', parsedData)
      axios({
        method: 'put',
        url: buildApiUrl('member'),
        data: parsedData,
        headers: {
          'x-tietoni-token': 'Bearer ' + bearerToken
        }
      }).then((res: any) => {
        console.log('res: ', res)
        evaluateToken()
        setIsLoading(false)
        setFormSent(true)
      })
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const handleNavigateBack = () => {
    navigate('/#info')
  }

  useEffect(() => setCursusPresent(user?.els_cursus?.els_oppilaitos !== undefined), [user])

  const onChangeSchool = (value: any) => {
    setCursusPresent(value.length > 0)
  }

  const marketingTooltip: {
    861120000: string
    861120001: string
    [key: number]: string
  } = {
    861120000:
      'Yhteystietosi saa luovuttaa Lääkäriliiton yhteistyökumppaneille ja sinulle saa lähettää yhteistyökumppaniemme jäsenetuihin liittyvää markkinointia',
    861120001:
      'Yhteystietosi saa luovuttaa Lääkärikompassi Oy:lle, jonka toimesta saat ammattiisi liittyvää markkinointiviestintää'
  }

  if (metaData && user) {
    return (
      <>
        {formSent ? (
          <FormSentConfirmation
            formName='Muokkaa henkilötietoja'
            longInfo='Tietosi ovat tallennettu'
          />
        ) : (
          <>
            <PageHeader />
            <div style={{ padding: '3vh' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Typography variant='h3' color='primary'>
                    Muokkaa henkilötietoja
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <ShadowContainer>
                      <Typography variant='h5' color='primary'>
                        Henkilötiedot
                      </Typography>
                      <Grid
                        container
                        direction='row'
                        justifyItems='flex-start'
                        alignItems='stretch'>
                        <Grid item xs={12}>
                          <FormLabel>Sukunimi</FormLabel>
                          <p>{user.lastname}</p>
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Etunimet</FormLabel>
                          <p>{user.firstname}</p>
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Henkilötunnus</FormLabel>
                          <p {...register('els_hetu')}>{user.els_hetu}</p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormLabel>Kutsumanimi</FormLabel>
                          <FormInput {...register('contactInfo.nickname')} />
                          <ErrorMessage>{errors?.contactInfo?.nickname?.message}</ErrorMessage>
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item xs={12} md={6}>
                          <FormLabel>Sähköpostiosoite</FormLabel>
                          <FormInput {...register('contactInfo.emailaddress1')} />
                          <ErrorMessage>{errors?.contactInfo?.emailaddress1?.message}</ErrorMessage>
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item xs={12} md={6}>
                          <FormLabel>Matkapuhelinnumero</FormLabel>
                          <FormInput {...register('contactInfo.mobilephone')} />
                          <ErrorMessage>{errors?.contactInfo?.mobilephone?.message}</ErrorMessage>
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Postituskieli</FormLabel>
                          <Controller
                            name='contactInfo.els_postikieli'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                sx={{ color: 'black' }}
                                displayEmpty
                                fullWidth
                                onChange={e => onChange(e.target.value)}
                                value={value || ''}>
                                <MenuItem value=''>---Valitse---</MenuItem>
                                {metaData.postikielet.map((mailLanguage: any, i: number) => (
                                  <MenuItem
                                    key={i}
                                    value={JSON.stringify({
                                      attributevalue: mailLanguage.attributevalue,
                                      value: mailLanguage.value
                                    })}>
                                    {mailLanguage.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                          <ErrorMessage>
                            {errors?.contactInfo?.els_postikieli?.message}
                          </ErrorMessage>
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Postiosoite</FormLabel>
                          <FormInputWide
                            disabled={user.els_postiintegraatio}
                            {...register('contactInfo.line1')}
                          />
                          <ErrorMessage>{errors?.contactInfo?.line1?.message}</ErrorMessage>
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Postiosoitteen rivi 2 (vapaaehtoinen)</FormLabel>
                          <FormInputWide
                            disabled={user.els_postiintegraatio}
                            {...register('contactInfo.line2')}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Postiosoitteen rivi 3 (vapaaehtoinen)</FormLabel>
                          <FormInputWide
                            disabled={user.els_postiintegraatio}
                            {...register('contactInfo.line3')}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormLabel>Postinumero</FormLabel>
                          <FormInput
                            disabled={user.els_postiintegraatio}
                            {...register('contactInfo.postalcode')}
                          />
                          <ErrorMessage>{errors?.contactInfo?.postalcode?.message}</ErrorMessage>
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item xs={12} md={6}>
                          <FormLabel>Postitoimipaikka</FormLabel>
                          <FormInput
                            disabled={user.els_postiintegraatio}
                            {...register('contactInfo.city')}
                          />
                          <ErrorMessage>{errors?.contactInfo?.city?.message}</ErrorMessage>
                        </Grid>

                        <Grid item xs={12}>
                          <FormLabel>Maa</FormLabel>
                          <Controller
                            name='contactInfo.country'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                size='small'
                                fullWidth
                                disablePortal
                                onChange={(e, val) => onChange(val)}
                                value={value || ''}
                                disableClearable
                                getOptionLabel={option =>
                                  option ? JSON.parse(option).els_koodinnimi : '--Valitse--'
                                }
                                options={[
                                  '',
                                  ...metaData.maat.map((country: ICountry) =>
                                    JSON.stringify({
                                      els_koodiid: country.els_koodiid,
                                      els_koodinnimi: country.els_koodinnimi
                                    })
                                  )
                                ]}
                                renderInput={params => <TextField {...params} fullWidth />}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ paddingBottom: '3vh' }}>
                          <FormLabel>Markkinointikielto voimassa</FormLabel>
                          {metaData?.markkinoinninestot?.map((block: MarketingBlock, i: number) => (
                            <Stack
                              key={i}
                              direction='row'
                              justifyContent='flex-start'
                              alignItems='flex-end'
                              spacing={1}>
                              <FormLabel key={block.value}>
                                <input
                                  {...register('contactInfo.els_markkinoininesto')}
                                  type='radio'
                                  style={{ color: 'green' }}
                                  value={JSON.stringify({
                                    attributevalue: block.attributevalue,
                                    value: block.value
                                  })}
                                />
                                {block.value}
                              </FormLabel>
                              {marketingTooltip[block.attributevalue] && (
                                <Tooltip title={marketingTooltip[block.attributevalue]}>
                                  <InfoIcon color='primary' />
                                </Tooltip>
                              )}
                            </Stack>
                          ))}
                        </Grid>
                        <Grid item xs={12} style={{ borderTop: '1px dotted black' }}>
                          <Typography variant='h5' color='primary'>
                            Opiskelutiedot
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justifyContent='space-between'
                          item
                          xs={12}
                          md={11}>
                          <Grid item xs={12}>
                            <FormLabel>Oppilaitos</FormLabel>
                            <Controller
                              name='contactInfo.cursus.els_oppilaitos'
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  displayEmpty
                                  fullWidth
                                  onChange={e => {
                                    onChange(e.target.value)
                                    onChangeSchool(e.target.value)
                                  }}
                                  value={value || ''}>
                                  {metaData?.oppilaitokset?.yliopistot?.map(
                                    (school: any, i: number) => (
                                      <MenuItem
                                        key={i}
                                        value={JSON.stringify({
                                          els_koodiid: school.els_koodiid,
                                          els_koodinnimi: school.els_koodinnimi
                                        })}>
                                        {school.els_koodinnimi}
                                      </MenuItem>
                                    )
                                  )}
                                  <Divider />
                                  {metaData?.oppilaitokset?.maat?.map((country: any, i: number) => (
                                    <MenuItem
                                      key={i}
                                      value={JSON.stringify({
                                        els_koodiid: country.els_koodiid,
                                        els_koodinnimi: country.els_koodinnimi
                                      })}>
                                      {country.els_koodinnimi}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />
                            <ErrorMessage>
                              {errors?.contactInfo?.cursus?.els_oppilaitos?.message}
                            </ErrorMessage>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormLabel>Aloitusvuosi</FormLabel>
                            <Controller
                              name='contactInfo.cursus.els_aloitusvuosi'
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  disabled={!cursusPresent}
                                  displayEmpty
                                  fullWidth
                                  onChange={e => onChange(e.target.value)}
                                  value={value || ''}>
                                  <MenuItem value=''>{'---Valitse---'}</MenuItem>
                                  {yearList().map(year => (
                                    <MenuItem value={`${year}`} key={year}>
                                      {year.toString()}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />
                            <ErrorMessage>
                              {errors.contactInfo?.cursus?.els_aloitusvuosi?.message}
                            </ErrorMessage>
                          </Grid>
                          <Grid item xs={12} md={6}></Grid>
                          <Grid item xs={12} md={6}>
                            <FormLabel>Lukukausi</FormLabel>
                            <Controller
                              name='contactInfo.cursus.els_lukukausi'
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  disabled={!cursusPresent}
                                  displayEmpty
                                  fullWidth
                                  onChange={e => onChange(e.target.value)}
                                  value={value || ''}>
                                  {metaData.lukukaudet.map((semester: ISemester) => (
                                    <MenuItem
                                      key={semester.attributevalue}
                                      value={JSON.stringify({
                                        attributevalue: semester.attributevalue,
                                        value: semester.value
                                      })}>
                                      {semester.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ErrorMessage>
                              {errors.contactInfo?.cursus?.els_lukukausi?.message}
                            </ErrorMessage>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ShadowContainer>
                    <Grid
                      container
                      spacing={3}
                      direction='row'
                      wrap='wrap-reverse'
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ paddingTop: '3vh' }}>
                      <Grid item xs={12} md={4}>
                        <LaakariButton variant='outlined' onClick={handleNavigateBack}>
                          Palaa etusivulle
                        </LaakariButton>
                      </Grid>
                      <Grid item xs={12} md={4} sx={{ textAlign: { md: 'right' } }}>
                        <LaakariButton type='submit' disabled={isSubmitting || isLoading}>
                          {isLoading ? <CircularProgress size={24.5} /> : 'Lähetä'}
                        </LaakariButton>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </>
    )
  } else {
    return (
      <div>
        <PageHeader />
        <LinearProgress />
      </div>
    )
  }
}

export default UpdatePersonalInfo
