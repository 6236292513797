const FMA = 'Suomen Lääkäriliitto'
const studentMemberKind = 'Opiskelija, ei jäsen'
const targetOrganizations = [FMA] // other could be added here when they have agreed to use this service for applications

const isCurrentMember = (user: any, filter?: (membership: any) => boolean) => {
  if (!user) {
    return false
  }
  const currentDate = new Date().toISOString()
  const currentMembership = user.memberships.find(
    (m: any) =>
      targetOrganizations.includes(m.yhdistys_name) &&
      m.els_jasenalkupvm &&
      m.els_jasenalkupvm < currentDate &&
      (!m.els_jasenloppu || m.els_jasenloppu > currentDate) &&
      (!filter || filter(m))
  )
  return currentMembership != undefined
}

// to show or hide student application button
export const isCurrentAnyMemberOfFMA = (user: any) =>
  isCurrentMember(user, membership => membership.yhdistys_name === FMA)

// to show or hide member application button
export const isCurrentProperMemberOfFMA = (user: any) =>
  isCurrentMember(
    user,
    membership =>
      membership.yhdistys_name === FMA &&
      !membership.els_jasentyyppi3_els_jasentyyppi.endsWith(studentMemberKind)
  )

// to show or hide menu links
export const isCurrentAnyMemberOfFMAOrOtherOrganization = (user: any) =>
  isCurrentMember(user, membership => targetOrganizations.includes(membership.yhdistys_name))

export const userFoundInAapeli = (user: any) => user?.els_henkilonumero != undefined
