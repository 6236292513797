import { yupResolver } from '@hookform/resolvers/yup'
import {
  Autocomplete,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material'
import React, { Fragment, useContext } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { ErrorMessage, FormInput, LaakariButton } from '../../../components/styled/uiComponents'
import { MetadataContext, UserContext } from '../../../context'
import { ICountry } from '../../../types'
import { generateDefaultStampDetails, StampDetails, StampKind, stampTypeLongNames } from './model'
import schema from './stampOrderSchema'

interface Props {
  stampKind: StampKind
  closeDialog: () => void
  confirmDetails: (details: StampDetails) => void
  stampDetails?: StampDetails
}

export const StampOrderForm: React.FC<Props> = ({
  stampKind,
  closeDialog,
  stampDetails,
  confirmDetails
}) => {
  const { user } = useContext(UserContext)
  const defaultValues = stampDetails || generateDefaultStampDetails(user, stampKind)
  const methods = useForm<StampDetails>({
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  })

  const { metaData } = useContext(MetadataContext)

  const {
    register,
    formState: { errors },
    handleSubmit,
    control
  } = methods

  const onSubmit = (data: StampDetails) => {
    confirmDetails(data)
  }

  const isPaidStamp = () => stampKind === 'PAID_STAMP'

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant='h5' color='primary'>
            Leimasimeen tulevat tiedot
          </Typography>
          <Grid container spacing={3} direction='row'>
            <Grid item xs={12} md={12}>
              <FormLabel>Nimi leimasimessa</FormLabel>
              <FormInput {...register('stampInfo.name')} disabled={!isPaidStamp()} />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormLabel>Arvo/ammatti</FormLabel>
              <FormInput {...register('stampInfo.title')} disabled={!isPaidStamp()} />
              <ErrorMessage>{errors.stampInfo?.title?.message?.toString()}</ErrorMessage>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormLabel>Yksilöintitunnus</FormLabel>
              <FormInput {...register('stampInfo.terhikki')} />
              <ErrorMessage>{errors.stampInfo?.terhikki?.message?.toString()}</ErrorMessage>
            </Grid>
            {isPaidStamp() && (
              <>
                <Grid item xs={12} md={12}>
                  <FormLabel>Leimasimen tyyppi</FormLabel>
                  <Controller
                    name='stampInfo.type'
                    control={control}
                    defaultValue={defaultValues.stampInfo.type}
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup onChange={onChange} value={value}>
                        {Object.entries(stampTypeLongNames).map(([typeId, typeLongName]) => (
                          <FormControlLabel
                            key={typeId}
                            value={typeId}
                            control={<Radio />}
                            label={typeLongName}
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormLabel>Kappalemäärä</FormLabel>
                  <FormInput type={'number'} {...register('stampInfo.quantity')} />
                  <ErrorMessage>{errors.stampInfo?.quantity?.message?.toString()}</ErrorMessage>
                </Grid>
              </>
            )}
          </Grid>

          <Typography variant='h5' color='primary' sx={{ paddingTop: '3vh' }}>
            Toimitusosoite
          </Typography>
          <Grid container spacing={3} direction='row'>
            {isPaidStamp() && (
              <Grid item xs={12} md={12}>
                <FormLabel>Tilaajan nimi</FormLabel>
                <FormInput {...register('deliveryAddress.name')} />
                <ErrorMessage>{errors.deliveryAddress?.name?.message?.toString()}</ErrorMessage>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <FormLabel>Katuosoite</FormLabel>
              <FormInput {...register('deliveryAddress.streetAddress')} />
              <ErrorMessage>
                {errors.deliveryAddress?.streetAddress?.message?.toString()}
              </ErrorMessage>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel>Postinumero</FormLabel>
              <FormInput {...register('deliveryAddress.postalCode')} />
              <ErrorMessage>{errors.deliveryAddress?.postalCode?.message?.toString()}</ErrorMessage>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel>Postitoimipaikka</FormLabel>
              <FormInput {...register('deliveryAddress.city')} />
              <ErrorMessage>{errors.deliveryAddress?.city?.message?.toString()}</ErrorMessage>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel>Maa</FormLabel>
              <Controller
                name='deliveryAddress.country'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size='small'
                    fullWidth
                    disablePortal
                    onChange={(e, val) => onChange(val)}
                    value={value || ''}
                    disableClearable
                    options={[
                      '',
                      ...metaData.maat.map((country: ICountry) => country.els_koodinnimi)
                    ]}
                    renderInput={params => <TextField {...params} fullWidth />}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel>Matkapuhelinnumero</FormLabel>
              <FormInput {...register('deliveryAddress.mobilePhone')} />
              <ErrorMessage>
                {errors.deliveryAddress?.mobilePhone?.message?.toString()}
              </ErrorMessage>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction='row'
            wrap='wrap-reverse'
            alignItems='center'
            justifyContent='space-between'
            sx={{ paddingTop: '3vh' }}>
            <Grid item xs={12} md={4}>
              <LaakariButton variant='outlined' onClick={closeDialog}>
                Takaisin
              </LaakariButton>
            </Grid>
            <Grid item xs={12} md={4}>
              <LaakariButton type='submit'>Hyväksy</LaakariButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Fragment>
  )
}
