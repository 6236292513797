import React, { Fragment } from 'react'
import { FormLabel } from '@mui/material'
import { ResultsP } from '../../../../components/styled/uiComponents'
import { replaceAll } from '../../../../utils/utils'

interface Props {
  formState: any
  errors: any
}

const ResignationSummary: React.FC<Props> = ({ formState }) => {
  const timeOfResignation = replaceAll(formState.timeOfResignation, '-', '.')
  const [year, month, day] = timeOfResignation.split('.')
  const printable = year.length === 4 ? `${day}.${month}.${year}` : timeOfResignation

  return (
    <Fragment>
      <div>
        <FormLabel>Organisaatio</FormLabel>
        <ResultsP>{formState.membership.yhdistys_name}</ResultsP>
      </div>
      <div>
        <FormLabel>Eropäivä</FormLabel>
        <ResultsP>{printable}</ResultsP>
      </div>
      <div>
        <FormLabel>Tilinumero</FormLabel>
        <ResultsP>{formState.bankAccount}</ResultsP>
      </div>
    </Fragment>
  )
}
export default ResignationSummary
