import React from 'react'
import { Grid } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAdminAuth } from './PersonnelAuthProvider'

interface StateType {
  from: { pathname: string }
}

const AdminLogin = () => {
  const navigate = useNavigate()
  const adminAuth = useAdminAuth()
  const location = useLocation()
  const state = location.state as StateType
  const from = state?.from?.pathname || '/adminLogin'

  const handleLogin = (event: any) => {
    event.preventDefault()

    adminAuth.handleAdminLogin(() => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true })
    })
  }
  return (
    <div>
      <h1>AdminLogin</h1>
      <Grid container direction='column'>
        <Grid item>
          <button onClick={handleLogin}>Log in</button>
        </Grid>
      </Grid>
    </div>
  )
}

export default AdminLogin
