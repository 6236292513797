import React from 'react'

interface Props {
  id: string
  isMobile: boolean
}

const Divider: React.FC<Props> = ({ id, isMobile }) => (
  <div
    id={id}
    style={
      isMobile
        ? { height: 10, borderBottom: '1px dotted black', backgroundColor: 'white' }
        : { height: '78px', backgroundColor: '#E5E5E5' }
    }></div>
)

export default Divider
