import { Typography } from '@mui/material'
import React from 'react'
import { ResultsP, FormLabel } from '../../../components/styled/uiComponents'
import { parseDate } from '../../../utils/utils'

interface Props {
  selectedApplication: any
  applicationType: string
}

const MemberApplicationDetails: React.FC<Props> = ({ selectedApplication, applicationType }) => {
  const application = {
    ...selectedApplication.data.formData,
    handlingEvents: selectedApplication.data.handlingEvents,
    identity: selectedApplication.data.personIdentity
  }

  const startOptions = [
    {
      value: 'WHEN_APPROVED',
      label: 'Haluan, että jäsenyyteni astuu voimaan välittömästi hallituksen hyväksymisen jälkeen'
    },
    {
      value: 'BEGINNING_OF_CALENDAR_YEAR',
      label: 'Haluan, että jäsenyyteni astuu voimaan seuraavan kalenterivuoden alusta'
    }
  ]

  const membershipStarts =
    application?.membership_starts === startOptions[0].value
      ? startOptions[0].label
      : startOptions[1].label

  return (
    <>
      <div>
        <Typography variant='h4' color='primary'>
          Hakemuksen tyyppi: {applicationType}
        </Typography>
      </div>
      <div>
        <FormLabel>Hakemus lähetetty</FormLabel>
        <ResultsP>{parseDate(selectedApplication.data.submitted)}</ResultsP>
      </div>
      <div>
        <FormLabel>Jäsenyys alkaa:</FormLabel>
        <ResultsP>{membershipStarts}</ResultsP>
      </div>
      <Typography variant='h3' color='primary'>
        Henkilötiedot
      </Typography>
      <div>
        <FormLabel>Sukunimi</FormLabel>
        <ResultsP>{application.lastname}</ResultsP>
      </div>
      <div>
        <FormLabel>Etunimet</FormLabel>
        <ResultsP>{application.firstname}</ResultsP>
      </div>
      {application.nickname && (
        <div>
          <FormLabel>Kutsumanimi</FormLabel>
          <ResultsP>{application.nickname}</ResultsP>
        </div>
      )}
      {application.identity.els_henkilonumero ? (
        <div>
          <FormLabel>Henkilönumero</FormLabel>
          <ResultsP>{application.identity.els_henkilonumero}</ResultsP>
        </div>
      ) : (
        <div>
          <FormLabel>Henkilötunnus</FormLabel>
          <ResultsP>{application.identity.els_hetu}</ResultsP>
        </div>
      )}
      {application.terhikkireknro && (
        <div>
          <FormLabel>
            Sosiaali- ja terveysalan lupa- ja valvontaviraston (Valvira) rekisteröintinumero
            (Terhikkinumero)
          </FormLabel>
          <ResultsP>{application.terhikkireknro}</ResultsP>
        </div>
      )}
      <div style={{ borderTop: '1px dotted black' }}>
        <Typography variant='h4' color='primary'>
          Yhteystiedot
        </Typography>
      </div>
      <div>
        <FormLabel>Postiosoite</FormLabel>
        <ResultsP>{application.address?.line1}</ResultsP>
      </div>
      {application.address.line2 && (
        <div>
          <FormLabel>Postiosoitteen rivi 2</FormLabel>
          <ResultsP>{application.address.line2}</ResultsP>
        </div>
      )}
      {application.address.line3 && (
        <div>
          <FormLabel>Postiosoitteen rivi 3</FormLabel>
          <ResultsP>{application.address.line3}</ResultsP>
        </div>
      )}
      <div>
        <FormLabel>Postinumero</FormLabel>
        <ResultsP>{application.address.postalcode}</ResultsP>
      </div>
      <div>
        <FormLabel>Postitoimipaikka</FormLabel>
        <ResultsP>{application.address.city}</ResultsP>
      </div>
      <div>
        <FormLabel>Postituskieli</FormLabel>
        <ResultsP>{application.address.els_postikieli.value}</ResultsP>
      </div>
      <div>
        <FormLabel>Maa</FormLabel>
        <ResultsP>{application.address.country.els_koodinnimi}</ResultsP>
      </div>
      <div>
        <FormLabel>Matkapuhelinnumero</FormLabel>
        <ResultsP>{application.mobilephone}</ResultsP>
      </div>
      <div>
        <FormLabel>Sähköpostiosoite</FormLabel>
        <ResultsP>{application.emailaddress1}</ResultsP>
      </div>

      <div style={{ borderTop: '1px dotted black' }}>
        <Typography variant='h4' color='primary'>
          Opiskelutiedot
        </Typography>
      </div>
      {application.cursus && (
        <>
          <div>
            <FormLabel>Oppilaitos</FormLabel>
            <ResultsP>{application.cursus.els_oppilaitos.els_koodinnimi}</ResultsP>
          </div>
          <div>
            <FormLabel>Aloitusvuosi</FormLabel>
            <ResultsP>{application.cursus.els_aloitusvuosi}</ResultsP>
          </div>
          <div>
            <FormLabel>Lukukausi</FormLabel>
            <ResultsP>{application.cursus.els_lukukausi.value}</ResultsP>
          </div>
        </>
      )}

      <div style={{ borderTop: '1px dotted black' }}>
        <Typography variant='h4' color='primary'>
          Työpaikkatiedot
        </Typography>
      </div>
      {application.employment ? (
        <>
          <div>
            <FormLabel>Työpaikka</FormLabel>
            <ResultsP>{application.employment.els_soteorganisaatio?.els_longname}</ResultsP>
          </div>
          <div>
            <FormLabel>Toimiala</FormLabel>
            <ResultsP>{application.employment.tyosuhdetoimiala?.value}</ResultsP>
          </div>
          <div>
            <FormLabel>Onko tämä päätoiminen työpaikkasi?</FormLabel>
            <ResultsP>{application.employment.els_paatoimi}</ResultsP>
          </div>
          <div>
            <FormLabel>Ammattinimike</FormLabel>
            <ResultsP>{application.employment.ammattinimike?.els_koodinnimi}</ResultsP>
          </div>
          <div>
            <FormLabel>Aloituspäivämäärä</FormLabel>
            <ResultsP>{application.employment.els_alkupvm}</ResultsP>
          </div>
        </>
      ) : (
        <div>
          <ResultsP>Työpaikkatietojen syöttö on ohitettu</ResultsP>
        </div>
      )}
      {application.handlingEvents && (
        <div>
          <FormLabel>Käsittelytapahtumat</FormLabel>
          {application.handlingEvents.map((handlingEvent: any, i: number) => (
            <div key={i}>
              <ResultsP>
                {handlingEvent.timestamp} - {handlingEvent.operation?.name} -{' '}
                {handlingEvent.handler?.name}
              </ResultsP>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default MemberApplicationDetails
