import React, { useContext } from 'react'

import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { SubHeaderH2 } from '../../../components/styled/uiComponents'
import { MembershipContext } from '../../../context'

interface Props {
  handleChangeAction: (e: any) => void
  selectedAction: any
  selectedOrg: any
}

const ChooseOrgAndAction: React.FC<Props> = ({ handleChangeAction, selectedAction }) => {
  const { contextMembership } = useContext(MembershipContext)

  const options = [
    { name: 'Hae alennusta jäsenmaksuun', id: 0 },
    { name: 'Ilmoita tilinumerosi jäsenmaksupalautusta varten', id: 1 },
    { name: 'Tee eroilmoitus', id: 2 }
  ]

  return (
    <>
      <SubHeaderH2>Jäsenyys</SubHeaderH2>
      <b>{`${contextMembership?.yhdistys_name} - ${contextMembership?.els_jasentyyppi}`}</b>

      <div style={{ paddingTop: '2vh' }}>
        <RadioGroup value={selectedAction}>
          {options.map(option => (
            <FormControlLabel
              control={<Radio />}
              key={option.id}
              value={option.id}
              label={option.name}
              onChange={handleChangeAction}
            />
          ))}
        </RadioGroup>
      </div>
    </>
  )
}

export default ChooseOrgAndAction
