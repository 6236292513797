import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Grid, MenuItem, Select, Typography } from '@mui/material'

import PageHeader from '../../../components/PageHeader'
import {
  FormLabel,
  LaakariButtonS,
  ShadowContainer,
  LaakariButton,
  FormInput,
  FormSubmitButton
} from '../../../components/styled/uiComponents'
import { EmploymentFormContext } from '../../../context/EmploymentFormContext'
import { StudentFormContext } from '../../../context/StudentFormContext'
import JobResultRow from '../../../components/JobResultRow'
import { IJobTitle, IWorkField } from '../../../types'
import { MetadataContext } from '../../../context'

/*
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
const validationSchema = yup.object({
  employment: yup.object().shape({
    els_tyosuhdesektori: yup.string().required(),
    els_soteorganisaatio: yup.string().required(),
    tyosuhdetoimiala: yup.string().required(),
    els_paatoimi: yup.string().required(),
    ammattinimike: yup.string().required(),
    els_alkupvm: yup.string().required()
  })
})
*/

const EmploymentFormP2 = () => {
  const [selectedOption, setSelectedOption] = useState('')
  const [selectedDate, setSelectedDate] = useState<string>('')

  const { contextJob, contextSector } = useContext(EmploymentFormContext)
  const { updatePayloadState, formState } = useContext(StudentFormContext)
  const { metaData } = useContext(MetadataContext)
  const navigate = useNavigate()
  const { register, handleSubmit, control } = useForm<any>({
    //resolver: yupResolver(validationSchema),
    defaultValues: {
      employment: {
        els_tyosuhdesektori: {
          attributevalue: contextSector.attributevalue,
          value: contextSector.value
        },
        els_soteorganisaatio: {
          els_longname: contextJob.els_longname,
          els_soteorganisaatiorekisteriid: contextJob.els_soteorganisaatiorekisteriid
        },
        tyosuhdetoimiala: '',
        els_paatoimi: '',
        ammattinimike: {
          els_koodiid: '',
          els_koodinnimi: ''
        },
        els_alkupvm: ''
      }
    }
  })

  const onSubmit = (data: any) => {
    updatePayloadState(formState, data)
    navigate('/results')
  }

  const onChangeValue = (e: any) => {
    setSelectedOption(e.target.value)
  }

  const handleNavigateBack = () => {
    navigate(-1)
  }

  const handleChangeSelectedDate = (e: any) => {
    setSelectedDate(e.target.value)
  }

  const placeHolder = (
    <>
      <Grid item xs={6}>
        <label>
          <input
            {...register('employment.els_paatoimi')}
            type='radio'
            value='yes'
            onChange={onChangeValue}
            checked={selectedOption === 'yes'}
          />
          Kyllä
        </label>
      </Grid>
      <Grid item xs={6} style={{ paddingTop: '2vh' }}>
        <label>
          <input
            {...register('employment.els_paatoimi')}
            type='radio'
            value='no'
            onChange={onChangeValue}
            checked={selectedOption === 'no'}
          />
          Ei
        </label>
      </Grid>
    </>
  )

  return (
    <>
      <PageHeader />
      <div style={{ padding: '3vh' }}>
        <Grid container>
          <Grid item xs={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ShadowContainer>
                <Typography variant='h3' color='primary'>
                  Työpaikka
                </Typography>
                <Grid item xs={11}>
                  <JobResultRow jobInfo={contextJob.els_longname} />
                </Grid>
                <Grid item style={{ textAlign: 'right' }}>
                  <LaakariButtonS onClick={handleNavigateBack}>VAIHDA</LaakariButtonS>
                </Grid>
                <div>
                  <FormLabel>Toimiala</FormLabel>
                  <Controller
                    name='employment.tyosuhdetoimiala'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        displayEmpty
                        fullWidth
                        onChange={e => onChange(e.target.value)}
                        value={value || ''}>
                        {metaData?.toimialat.map((workField: IWorkField) => (
                          <MenuItem
                            key={workField.value}
                            value={JSON.stringify({
                              attributevalue: workField.attributevalue,
                              value: workField.value
                            })}>
                            {workField.value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
                <Grid container direction='column' spacing={3}>
                  <Grid item>
                    <FormLabel>Onko tämä päätoiminen työpaikkasi?</FormLabel>
                  </Grid>
                  <Grid item>{placeHolder}</Grid>
                </Grid>
                <div>
                  <FormLabel>Ammattinimike</FormLabel>
                  <Controller
                    name='employment.ammattinimike.els_koodinnimi'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        displayEmpty
                        fullWidth
                        onChange={e => onChange(e.target.value)}
                        value={value || ''}>
                        {metaData.ammattinimikkeet.map((title: IJobTitle) => (
                          <MenuItem
                            key={title.els_koodiid}
                            value={JSON.stringify({
                              els_koodiid: title.els_koodiid,
                              els_koodinnimi: title.els_koodinnimi
                            })}>
                            {title.els_koodinnimi}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
                <div>
                  <FormLabel>Aloituspäivämäärä</FormLabel>
                  <FormInput
                    {...register('employment.els_alkupvm')}
                    type='date'
                    onChange={handleChangeSelectedDate}
                  />
                </div>
              </ShadowContainer>
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                style={{ paddingTop: '3vh' }}>
                <Grid item>
                  <LaakariButton variant='outlined' onClick={handleNavigateBack}>
                    Takaisin
                  </LaakariButton>
                </Grid>
                <Grid item>
                  <FormSubmitButton type='submit' value='Jatka' disabled={!selectedDate} />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default EmploymentFormP2
