import React, { useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Grid } from '@mui/material'
import { parseDate } from '../../utils/utils'

import {
  ShadowTableRow,
  ContainerHeaderH1,
  InfoContainer
} from '../../components/styled/uiComponents'
import { UserContext } from '../../context'
import Divider from './Divider'
import MobileTitle from './MobileTitle'

const Qualifications = () => {
  const { user } = useContext(UserContext)
  const [isOpen, setIsOpen] = useState(!isMobile)
  return (
    <>
      <Divider id='qualifications' isMobile={isMobile} />
      <InfoContainer>
        <Grid container alignItems='center'>
          {isMobile ? (
            <MobileTitle isOpen={isOpen} setIsOpen={setIsOpen} title='Pätevyystiedot' />
          ) : (
            <Grid item xs={12}>
              <ContainerHeaderH1>Pätevyystiedot</ContainerHeaderH1>
            </Grid>
          )}
          {isOpen && (
            <Grid container alignItems='center'>
              {user?.qualifications?.map((qualification: any, i: number) => (
                <ShadowTableRow key={i}>
                  <Grid
                    container
                    direction={isMobile ? 'column' : 'row'}
                    alignItems={isMobile ? 'start' : 'center'}>
                    <Grid item xs={12} md={3}>
                      <h3>Pätevyys</h3>
                      <p>{qualification.tutkinnon_ylaluokka}</p>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <h3>Suorituspaikka</h3>
                      <p>{qualification.oppilaitos}</p>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <h3>Suoritusaika</h3>
                      <p>{parseDate(qualification.els_paatospvm)}</p>
                    </Grid>
                  </Grid>
                </ShadowTableRow>
              ))}
            </Grid>
          )}
        </Grid>
      </InfoContainer>
    </>
  )
}

export default Qualifications
