import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../context/AuthProvider'

interface Props {
  children: React.ReactNode
}

const PublicOutlet: React.FC<Props> = ({ children }) => {
  const auth = useAuth()

  return !auth?.bearerToken ? ( //Check if logged in
    <>
      {children}
      <Outlet />
    </>
  ) : (
    <Navigate to='/' replace /> //Go to protected route if logged in
  )
}

export default PublicOutlet
