import { yupResolver } from '@hookform/resolvers/yup'
import { FormLabel, Grid } from '@mui/material'
import axios from 'axios'
import React, { Fragment, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { AuthContext } from '../../../context/AuthProvider'
import FormStepper from '../../../components/FormStepper'
import {
  ErrorMessage,
  LaakariButton,
  ResultsP,
  ShadowContainer,
  SubHeaderH3
} from '../../../components/styled/uiComponents'
import { MetadataContext, UserContext } from '../../../context'
import { CreateJobFormData } from '../../../types/job'
import { isSessionInvalidError } from '../../../utils/error'
import { buildApiUrl, parseEmploymentValues, parseNewJobFormData } from '../../../utils/utils'
import EmploymentForm from '../../Forms/MembershipForm/EmploymentForm'
import EmploymentForm2 from '../../Forms/MembershipForm/EmploymentForm2'

interface JobSummaryProps {
  formState: any
}

const JobSummary: React.FC<JobSummaryProps> = ({ formState }) => {
  const employmentValues = formState.employment
    ? parseEmploymentValues(formState.employment)
    : undefined
  return (
    <Fragment>
      <SubHeaderH3>Työpaikkatiedot</SubHeaderH3>
      <div>
        <FormLabel>Työpaikka</FormLabel>
        <ResultsP>{employmentValues.els_soteorganisaatio?.els_longname}</ResultsP>
      </div>
      <div>
        <FormLabel>Toimiala</FormLabel>
        <ResultsP>{employmentValues.tyosuhdetoimiala?.value}</ResultsP>
      </div>
      <div>
        <FormLabel>Onko tämä päätoiminen työpaikkasi?</FormLabel>
        <ResultsP>{formState.employment.els_paatoimi ? 'Kyllä' : 'Ei'}</ResultsP>
      </div>
      <div>
        <FormLabel>Ammattinimike</FormLabel>
        <ResultsP>{employmentValues.ammattinimike?.els_koodinnimi}</ResultsP>
      </div>
      <div>
        <FormLabel>Aloituspäivämäärä</FormLabel>
        <ResultsP>{formState.employment.els_alkupvm}</ResultsP>
      </div>
    </Fragment>
  )
}

interface Props {
  closeDialog: () => void
}

const CreateJobForm: React.FC<Props> = ({ closeDialog }) => {
  const [page, setPage] = useState(0)
  const { user } = useContext(UserContext)
  const { metaData } = useContext(MetadataContext)
  const { bearerToken, evaluateToken, handleLogout } = useContext(AuthContext)
  const [submitError, setSubmitError] = useState(false)

  const navigate = useNavigate()

  const employment1Schema = yup.object().shape({
    employment: yup
      .object()
      .shape({
        els_tyosuhdesektori: yup.string().required(),
        els_soteorganisaatio: yup.string().required('Täytä kaikki kentät ja valitse työpaikka')
      })
      .required()
  })

  const employment2Schema = yup.object().shape({
    employment: yup.object().shape({
      tyosuhdetoimiala: yup.string().required('Täytä puuttuva kenttä'),
      els_paatoimi: yup.string().required('Täytä puuttuva kenttä'),
      ammattinimike: yup.string().required('Täytä puuttuva kenttä'),
      els_alkupvm: yup.string().required('Täytä puuttuva kenttä')
    })
  })

  /** Empty schema since Summary page doesn't have any fields */
  const summarySchema = yup.object().shape({})

  const createJobSchema = [employment1Schema, employment2Schema, summarySchema]

  const currentValidationSchema = createJobSchema[page]

  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitting }
  } = useForm<CreateJobFormData>({
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(currentValidationSchema)
  })

  const handleNext = async () => {
    const isStepValid = await trigger()
    if (isStepValid) {
      setPage(prevActivePage => prevActivePage + 1)
    }
  }

  const handleBack = () => {
    setPage(prevActivePage => prevActivePage - 1)
    if (submitError) {
      setSubmitError(false)
    }
  }

  const onSubmit = async (data: any) => {
    try {
      const employment = parseNewJobFormData(data)
      const url = buildApiUrl('employment')
      await axios.post(url, employment, {
        headers: { 'x-tietoni-token': `Bearer ${bearerToken}` }
      })
      evaluateToken()
      closeDialog()
    } catch (error) {
      setSubmitError(true)
      console.log('error when submitting form', error)
      if (isSessionInvalidError(error)) {
        closeDialog()
        toast.error('Istunto on päättynyt. Kirjaudu uudelleen sisään.')
        handleLogout(() => navigate('/'))
      }
    }
  }

  const jobValue = watch('employment.els_soteorganisaatio')
  const pageProps = { register, user, errors, metaData, getValues, control }
  return (
    <Fragment>
      <FormStepper activeStep={page} stepsCount={3} />
      {metaData && (
        <Fragment>
          <form>
            <ShadowContainer>
              {page === 0 && <EmploymentForm watch={watch} {...pageProps} />}
              {page === 1 && (
                <EmploymentForm2
                  jobValue={jobValue}
                  removeJob={() => {
                    setValue('employment', undefined)
                    setPage(page - 1)
                  }}
                  {...pageProps}
                />
              )}
              {page === 2 && <JobSummary formState={getValues()} />}
            </ShadowContainer>

            <Grid
              container
              spacing={3}
              direction='row'
              wrap='wrap-reverse'
              alignItems='center'
              justifyContent='space-between'
              sx={{ paddingTop: '3vh' }}>
              {page === 0 && (
                <Grid item xs={12} md={4}>
                  <LaakariButton variant='outlined' onClick={closeDialog}>
                    Takaisin
                  </LaakariButton>
                </Grid>
              )}
              {page !== 0 && (
                <Grid item xs={12} md={4}>
                  <LaakariButton variant='outlined' onClick={handleBack}>
                    Takaisin
                  </LaakariButton>
                </Grid>
              )}
              {page !== 2 ? (
                <Grid item xs={12} md={4}>
                  <LaakariButton onClick={handleNext}>Jatka</LaakariButton>
                </Grid>
              ) : (
                <Grid item xs={12} md={4}>
                  <LaakariButton disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
                    Lähetä
                  </LaakariButton>
                </Grid>
              )}
            </Grid>
          </form>
        </Fragment>
      )}
      {submitError && <ErrorMessage>Jotain meni pieleen lisätessä työpaikkaa</ErrorMessage>}
    </Fragment>
  )
}

export default CreateJobForm
