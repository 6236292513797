import React, { createContext, useState } from 'react'

export const UserContext = createContext<any>(null)

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<any>(null)
  const [merchState, setMerchState] = useState()

  const currentJob = user?.employments?.find((job: any) => job.els_paatoimi === true)

  const value = { user, setUser, merchState, setMerchState, currentJob }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContextProvider
