import {
  Autocomplete,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import PageHeader from '../../../components/PageHeader'
import {
  FormLabel,
  MainHeaderH1,
  ShadowContainer,
  LaakariButton,
  ErrorMessage,
  FormInputWide,
  FormSubmitButton
} from '../../../components/styled/uiComponents'

import { EmploymentFormContext, MetadataContext } from '../../../context'
import { buildApiUrl } from '../../../utils/utils'
import { IJob, IJobSearchForm, IJobSector } from '../../../types'

const validationSchema = yup.object({
  sector: yup.string().required('Täytä puuttuva kenttä'),
  city: yup.string().required('Täytä puuttuva kenttä'),
  streetAddressQuery: yup.string().required('Täytä puuttuva kenttä'),
  longNameQuery: yup.string().required('Täytä puuttuva kenttä')
})

const EmploymentForm = () => {
  const [sector, setSector] = useState<boolean>(false)
  const [city, setCity] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<any>()
  const [selectedJob, setSelectedJob] = useState<IJob>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [jobName, setJobName] = useState<string>('')

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IJobSearchForm>({ resolver: yupResolver(validationSchema) })

  const { setContextJob, setContextSector } = useContext(EmploymentFormContext)
  const navigate = useNavigate()

  const { metaData } = useContext(MetadataContext)

  const handleFetchJobs = (data: any) => {
    setIsLoading(true)
    axios
      .get(buildApiUrl('metadata'), {
        params: {
          option: 'workplace',
          sector: data.sector,
          city: data?.city,
          streetAddressQuery: data.streetAddressQuery,
          longnameQuery: data.longNameQuery
        }
      })
      .then(res => {
        setSearchResults(res.data)
        setIsLoading(false)
      })
  }

  const onSubmit = (data: any) => {
    handleFetchJobs(data)
  }

  const handleSelectJob = (e: any) => {
    setSelectedJob(searchResults?.filter((job: any) => e.target.value === job.els_longname)[0])
  }

  const handleNavigateBack = () => {
    navigate(-1)
  }

  const handleUpdateJobState = () => {
    setContextJob(selectedJob)
    navigate('/p3')
  }

  const handleChangeSector = (e: any) => {
    const fullSectorValue = metaData.sektorit.filter(
      (sector: IJobSector) => sector.value === e.target.value
    )

    setContextSector({
      attributevalue: fullSectorValue[0].attributevalue,
      value: fullSectorValue[0].value
    })

    setSector(e.target.value)
  }

  const handleSkipEmployment = () => {
    navigate('/results')
  }

  const handleChangeJobName = (e: any) => {
    setJobName(e.target.value)
  }

  const buttonContainer = sector ? (
    <FormSubmitButton disabled={!jobName} type='submit' value='Hae' />
  ) : (
    <LaakariButton onClick={handleSkipEmployment}>Ohita työpaikkatietojen täyttö</LaakariButton>
  )

  return (
    <>
      <PageHeader />
      <div style={{ padding: '3vh' }}>
        <Grid container>
          <Grid item xs={8}>
            <MainHeaderH1>Ilmoittaudu lääketieteen opiskelijaksi</MainHeaderH1>
          </Grid>
          <Grid item xs={8}>
            <ShadowContainer>
              <h4 style={{ color: 'red' }}>Valitse seuraavat: </h4>
              <h4 style={{ color: 'red' }}>Työnantajasektori: yksityinen </h4>
              <h4 style={{ color: 'red' }}>Kaupunki: Helsinki/Tampere </h4>
              <h4 style={{ color: 'red' }}>Katuosoite: keskus </h4>
              <h4 style={{ color: 'red' }}>Työpaikan nimi: keskus </h4>
              <Typography variant='h3' color='primary'>
                Työpaikka
              </Typography>

              <p>Täytä työpaikkaasi koskevat tiedot ja valitse se hakutuloksista</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid item xs={6}>
                      <FormLabel>Työnantajasektori</FormLabel>
                      <Controller
                        name='sector'
                        control={control}
                        render={({ field: { value } }) => (
                          <Select fullWidth onChange={handleChangeSector} value={value || ''}>
                            {metaData.sektorit.map((sector: IJobSector) => (
                              <MenuItem key={sector.value} value={sector.value}>
                                {sector.value}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>
                  </Grid>
                  {sector ? (
                    <>
                      <Grid item xs={12}>
                        <Grid item xs={6}>
                          <FormLabel>Kaupunki</FormLabel>
                          <Controller
                            name='city'
                            control={control}
                            render={({ field: { value } }) => (
                              <Autocomplete
                                size='small'
                                value={value}
                                fullWidth
                                disablePortal
                                onChange={(e, val: any) => setCity(val)}
                                options={metaData.tyopaikka_kaupungit.map((city: string) =>
                                  city.toLocaleUpperCase()
                                )}
                                renderInput={params => <TextField {...params} fullWidth />}
                              />
                            )}
                          />
                          <ErrorMessage>{errors.city?.message}</ErrorMessage>
                        </Grid>
                      </Grid>
                      {city && (
                        <>
                          <div>
                            <FormLabel>Työpaikan katuosoite</FormLabel>
                            <FormInputWide {...register('streetAddressQuery')} />
                            <ErrorMessage>{errors.streetAddressQuery?.message}</ErrorMessage>
                          </div>

                          <div>
                            <FormLabel>Työpaikan nimi</FormLabel>
                            <FormInputWide
                              {...register('longNameQuery')}
                              onChange={handleChangeJobName}
                            />
                            <ErrorMessage>{errors.longNameQuery?.message}</ErrorMessage>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                  {!sector && (
                    <Grid
                      item
                      xs={12}
                      style={{ borderTop: '1px dotted black', marginTop: '3vh' }}></Grid>
                  )}
                  <Grid item xs={12} style={{ marginTop: '3vh', textAlign: 'right' }}>
                    {buttonContainer}
                  </Grid>
                </Grid>
              </form>
              {isLoading ? (
                <LinearProgress />
              ) : (
                searchResults && (
                  <Grid item xs={8}>
                    <Typography variant='h3' color='primary'>
                      Hakutulokset
                    </Typography>
                    {searchResults?.map((job: IJob) => (
                      <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        key={job.els_longname}
                        style={{
                          width: '108vh',
                          border: '1px solid #b6b6b6',
                          borderRadius: '3px',
                          marginBottom: '1vh',
                          padding: '3vh'
                        }}>
                        <Grid item xs={1}>
                          <input
                            type='radio'
                            value={job.els_longname}
                            checked={selectedJob?.els_longname === job.els_longname}
                            onChange={handleSelectJob}
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <label>{job.els_longname}</label>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )
              )}
            </ShadowContainer>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              style={{ paddingTop: '3vh' }}>
              <Grid item>
                <LaakariButton variant='outlined' onClick={handleNavigateBack}>
                  Takaisin
                </LaakariButton>
              </Grid>
              <Grid item style={{ textAlign: 'right' }}>
                <LaakariButton onClick={handleUpdateJobState} disabled={!selectedJob}>
                  Jatka
                </LaakariButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default EmploymentForm
