import React from 'react'
import ButtonToFrontpage from '../components/ButtonToFrontpage'
import PageHeader from '../components/PageHeader'

const PageNotFound = () => {
  return (
    <>
      <PageHeader buttonsHidden={true} />
      <div style={{ margin: 'auto', width: '50%', padding: '10px', textAlign: 'center' }}>
        <h1>Sivua ei löydy</h1>
        <ButtonToFrontpage />
      </div>
    </>
  )
}

export default PageNotFound
