import React, { useContext } from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import { isMobile } from 'react-device-detect'
import { useTheme } from '@mui/material/styles'
import {
  InfoContainer,
  MainHeaderH1,
  SubHeaderH2,
  SubHeaderH3
} from '../../components/styled/uiComponents'
import { UserContext } from '../../context'
import { isCurrentAnyMemberOfFMAOrOtherOrganization } from '../../utils/membershipChecks'
import ContactInfoMissing from './ContactInfoMissing'

const MainInfo = () => {
  const { user, currentJob } = useContext(UserContext)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <InfoContainer>
      <ContactInfoMissing />
      <Grid container>
        <Grid item xs={12} md={6}>
          <div
            style={
              isMobile || fullScreen
                ? { borderBottom: '1px dotted black' }
                : { borderRight: '1px dotted black' }
            }>
            <Grid item xs={12} md={6}>
              <MainHeaderH1>{`${user.firstname} ${user.lastname}`}</MainHeaderH1>
            </Grid>
            <Grid item xs={12} md={6}>
              <SubHeaderH2>{currentJob?.ammattinimike?.els_koodinnimi}</SubHeaderH2>
              <SubHeaderH3>{currentJob?.els_nimi}</SubHeaderH3>
            </Grid>
          </div>
        </Grid>
        {isCurrentAnyMemberOfFMAOrOtherOrganization(user) && (
          <Grid item xs={12} md={6}>
            <div style={!isMobile ? { paddingLeft: '3vh' } : undefined}>
              <Grid item xs={12} md={6} sx={{ paddingTop: '3vh' }}>
                <SubHeaderH2 style={{ color: 'black' }}>
                  <b>Paikallisosastosi</b>
                </SubHeaderH2>
                <p>{user?.paikallisosasto?.els_paikallisosasto2_els_paikallisosasto}</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <SubHeaderH2 style={{ color: 'black' }}>
                  <b>Alaosastosi</b>
                </SubHeaderH2>
                <p>{user?.alaosasto?.alaosasto_els_alaosasto}</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <SubHeaderH2 style={{ color: 'black' }}>
                  <b>Jäsennumero</b>
                </SubHeaderH2>
                <p>{user?.els_henkilonumero}</p>
              </Grid>
            </div>
          </Grid>
        )}
      </Grid>
    </InfoContainer>
  )
}

export default MainInfo
