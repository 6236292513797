import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LaakariButton } from './styled/uiComponents'

interface Props {
  subUrl?: string
}

const ButtonToFrontpage: React.FC<Props> = ({ subUrl }) => {
  const navigate = useNavigate()
  const handleNavigateFrontpage = () => {
    subUrl ? navigate(`/${subUrl}`) : navigate('/')
  }
  return <LaakariButton onClick={handleNavigateFrontpage}>Palaa etusivulle</LaakariButton>
}

export default ButtonToFrontpage
