import React from 'react'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { Button } from '@mui/material'

interface ComponentProps {
  label: string
  handleClick?: () => void
}

const LinkWithArrow = ({ label, handleClick }: ComponentProps) => (
  <Button
    variant='text'
    style={{ color: 'black' }}
    onClick={handleClick}
    endIcon={<ArrowCircleRightIcon color='primary' />}>
    {label}
  </Button>
)

export default LinkWithArrow
