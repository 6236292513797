export const isLicensedPhysician = (user: any) =>
  user.qualifications.find((q: any) => q.tutkinto_laillistus === 'Laillistettu lääkäri') !=
  undefined

export const isFourthOrFifthYearStudent = (user: any) =>
  user.qualifications.find((q: any) =>
    ['Lääketieteen opiskelija 4. vuosi', 'Lääketieteen opiskelija 5. vuosi'].includes(
      q.tutkinto_laillistus
    )
  )
