import React, { Fragment, useState, useEffect, useCallback } from 'react'
import {
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography
} from '@mui/material'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import axios from 'axios'
import { Controller, UseFormWatch } from 'react-hook-form'
import { ErrorMessage, FormInputWide, LaakariButton } from '../../../components/styled/uiComponents'
import { CommonFormSectionProps, IJob, IJobSector } from '../../../types'
import { compareStrings } from '../../../utils/sorting'
import { buildApiUrl } from '../../../utils/utils'

interface Props extends CommonFormSectionProps {
  watch: UseFormWatch<any>
  skipEmployment?: () => void
}

const EmploymentForm: React.FC<Props> = ({
  control,
  watch,
  metaData: { sektorit },
  errors,
  skipEmployment
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<IJob[]>()
  const [streetAddressQuery, setStreetAddressQuery] = useState('')
  const [longnameQuery, setLongnameQuery] = useState('')
  const [apiError, setApiError] = useState<any>()

  const sector = watch('employment.els_tyosuhdesektori')

  const escapeSearchCharacters = (search: string) =>
    search.replace(/[-+&|!(){}[\]^"~*?:\\/]/g, '\\$&')

  const preprocessAddress = (address?: string) => {
    if (address) {
      const quotedOrFuzzy =
        address.split(/\s+/).length > 1 ? `"${address}"` : `${escapeSearchCharacters(address)}~`
      return `+${quotedOrFuzzy}`
    }
    return address
  }

  const preprocessLongName = (longName?: string) => {
    if (longName) {
      return longName
        .split(/\s+/)
        .map(part => `+${escapeSearchCharacters(part)}~`)
        .join(' ')
    }
    return longName
  }

  const buildSearchString = (
    city?: string,
    streetAddressQuery?: string,
    longNameQuery?: string
  ) => {
    const cityTerm = city ? `+"${city}"` : undefined
    const addressTerm = preprocessAddress(streetAddressQuery)
    const nameTerm = preprocessLongName(longNameQuery)

    const searchString = [cityTerm, addressTerm, nameTerm].filter(term => term).join(' ')
    console.log(`Search string: [${searchString}]`)
    return searchString
  }

  const handleFetchJobs = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    streetAddressQuery: string,
    longnameQuery: string,
    city?: string
  ) => {
    e.preventDefault()
    try {
      setSearchResults(undefined)
      setApiError(null)
      setIsLoading(true)
      const res = await axios.get(buildApiUrl('metadata'), {
        params: {
          option: 'workplace',
          search: buildSearchString(city, streetAddressQuery, longnameQuery)
          /*
          sector: sector ? JSON.parse(sector).value : '',
          city,
          streetAddressQuery,
          longnameQuery
          */
        }
      })
      setSearchResults(
        res.data.map((workplace: any) => ({
          ...workplace,
          els_soteorganisaatiorekisteriid:
            workplace.elssoteorganisaatiorekisteriid || workplace['@search.objectid']
        }))
      )
      setIsLoading(false)
    } catch (error: any) {
      console.log(error.response.data.message)
      if (error.response.data.message.startsWith('Too many results')) {
        setApiError('Liian monta hakutulosta, tarkenna hakuvalintojasi')
      } else {
        setApiError(null)
      }
      setIsLoading(false)
    }
  }

  const handleKeypress = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        handleFetchJobs(e, streetAddressQuery, longnameQuery)
      }
    },
    [handleFetchJobs]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeypress)
    return () => document.removeEventListener('keydown', handleKeypress)
  }, [handleKeypress])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h5' color='primary'>
          Työpaikka
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <p>{`Valitse ensin työnantajasektori, täytä työpaikkaasi koskevat tiedot ja valitse se hakutuloksista. Työpaikkalistaus perustuu Terveyden ja hyvinvoinnin laitos THL:n ylläpitämään SOTE-rekisteriin. Rekisteriin syötetyn työpaikan nimi saattaa poiketa arkipäivässä käytetystä nimestä, eikä välttämättä sisällä lyhenteitä (TAYS, OYS jne). Hakuehtoina kannattaaensin kokeilla kadunnimeä ja numeroa sekä työpaikan virallista nimeä. Esim. “konstaapelinkatu 2” + “leppävaaran terveysasema” tai “ahertajantie 2” + “tapiolan terveysasema”. Työpaikan kadunnimi ja numero -> Työpaikan katuosoite`}</p>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={6}>
          <FormLabel>Työnantajasektori</FormLabel>
          <Controller
            name='employment.els_tyosuhdesektori'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                displayEmpty
                fullWidth
                onChange={e => onChange(e.target.value)}
                value={value || ''}>
                <MenuItem value=''>{'---Valitse---'}</MenuItem>
                {sektorit
                  .sort(compareStrings('value'))
                  .map(({ value, attributevalue }: IJobSector) => (
                    <MenuItem
                      key={attributevalue}
                      value={JSON.stringify({
                        attributevalue,
                        value
                      })}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>
      {sector && (
        <>
          {
            <>
              <Grid item xs={12}>
                <FormLabel>
                  Työpaikan kadunnimi ja numero
                  <Tooltip title='Kirjoita vähintään osa osoitteesta'>
                    <InfoIcon color='primary' />
                  </Tooltip>
                </FormLabel>
                <FormInputWide
                  onChange={e => setStreetAddressQuery(e.target.value)}
                  pattern='[\w ]*'
                />
                <ErrorMessage>{errors?.streetAddressQuery?.message}</ErrorMessage>
              </Grid>

              <Grid item xs={12}>
                <FormLabel>
                  Työpaikan nimi
                  <Tooltip title='Kirjoita vähintään osa työpaikan nimestä'>
                    <InfoIcon color='primary' />
                  </Tooltip>
                </FormLabel>
                <FormInputWide onChange={e => setLongnameQuery(e.target.value)} />
                <ErrorMessage>{errors?.longNameQuery?.message}</ErrorMessage>
              </Grid>
            </>
          }
        </>
      )}
      {!sector && (
        <Grid item xs={12}>
          <div style={{ borderTop: '1px dotted black', marginTop: '3vh' }}></div>
        </Grid>
      )}
      <Grid item xs={12}>
        <ErrorMessage>{errors.employment?.els_soteorganisaatio?.message}</ErrorMessage>
      </Grid>
      <Grid
        container
        direction='row-reverse'
        item
        xs={12}
        style={{ marginTop: '3vh', textAlign: 'right' }}>
        {sector ? (
          <Grid item xs={12} md={3}>
            <LaakariButton
              variant='outlined'
              disabled={
                isLoading ||
                streetAddressQuery.trim().length === 0 ||
                longnameQuery.trim().length === 0
              }
              onKeyDown={e => handleKeypress(e)}
              tabIndex={0}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                handleFetchJobs(e, streetAddressQuery, longnameQuery)
              }>
              Hae
            </LaakariButton>
          </Grid>
        ) : (
          skipEmployment && (
            <Grid item xs={12} md={5}>
              <LaakariButton variant='outlined' onClick={() => skipEmployment()}>
                Ohita työpaikkatietojen täyttö
              </LaakariButton>
            </Grid>
          )
        )}
      </Grid>

      {isLoading && <LinearProgress />}
      {!isLoading && searchResults && (
        <Grid item xs={12}>
          <Typography variant='h4' color='primary'>
            Hakutulokset
          </Typography>
          {searchResults.length > 0 ? (
            <Grid item xs={12}>
              <Controller
                name='employment.els_soteorganisaatio'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup onChange={e => onChange(e.target.value)} value={value || ''}>
                    {searchResults.map(({ els_longname, els_soteorganisaatiorekisteriid }) => (
                      <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        key={els_soteorganisaatiorekisteriid}
                        style={{
                          border: '1px solid #b6b6b6',
                          borderRadius: '3px',
                          marginBottom: '1vh',
                          padding: '3vh'
                        }}>
                        <FormControlLabel
                          key={els_longname}
                          value={JSON.stringify({
                            els_longname,
                            els_soteorganisaatiorekisteriid
                          })}
                          control={<Radio />}
                          label={els_longname}
                        />
                      </Grid>
                    ))}
                  </RadioGroup>
                )}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <ErrorMessage>Ei hakutuloksia</ErrorMessage>
            </Grid>
          )}
        </Grid>
      )}
      {apiError && (
        <Grid item>
          <ErrorMessage>{apiError}</ErrorMessage>
        </Grid>
      )}
    </Grid>
  )
}

export default EmploymentForm
