import React, { useContext, useState } from 'react'
import { Alert, AlertTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../context'

const ContactInfoMissing = () => {
  const [open, setOpen] = useState<boolean>(true)
  const { user } = useContext(UserContext)
  const navigate = useNavigate()

  const infoMissing = !user.emailaddress1 || !user.mobilephone
  const isMember = user.els_henkilonumero != undefined

  const emailMissing = !user.emailaddress1
  const phoneMissing = !user.mobilephone

  const handleNavigateEditInfo = () => {
    navigate('/updateInfo')
  }

  const renderErrorText = () => {
    if (emailMissing && phoneMissing) {
      return 'Sekä sähköposti että puhelinnumero puuttuu.'
    } else if (emailMissing) {
      return 'Sähköposti puuttuu.'
    } else if (phoneMissing) {
      return 'Puhelinnumero puuttuu.'
    }
  }

  if (infoMissing && isMember) {
    return (
      <Alert
        sx={{ display: open ? 'flex' : 'none' }}
        severity='warning'
        action={
          <IconButton
            aria-label='close alert'
            color='inherit'
            onClick={() => {
              setOpen(false)
            }}>
            <CloseIcon />
          </IconButton>
        }>
        <AlertTitle>{renderErrorText()}</AlertTitle>
        <strong style={{ cursor: 'pointer' }} onClick={handleNavigateEditInfo}>
          Klikkaa tästä täydentääksesi tiedot
        </strong>
      </Alert>
    )
  } else {
    return <></>
  }
}

export default ContactInfoMissing
