import { format, parseISO } from 'date-fns'
import IBAN from 'iban'
import { TestFunction } from 'yup'
import { CreateJobFormData, NewJob } from '../types/job'

export const buildApiUrl = (endpointString: string, sessionId?: string, test?: boolean) => {
  if (test) {
    return `https://localhost:8080${endpointString}?key=${process.env.REACT_APP_API_KEY}`
  }

  if (sessionId) {
    return `${process.env.REACT_APP_API_BASE_URL}${endpointString}/${sessionId}?key=${process.env.REACT_APP_API_KEY}`
  } else {
    return `${process.env.REACT_APP_API_BASE_URL}${endpointString}?key=${process.env.REACT_APP_API_KEY}`
  }
  console.log('request made!')
}

const parseNestedJsonStrings = (stringifiedFormData: string): any => {
  try {
    return JSON.parse(stringifiedFormData, (key, val) => {
      if (typeof val === 'string') {
        return parseNestedJsonStrings(val)
      }
      if (typeof val === 'number') {
        return `${val}`
      }
      return val
    })
  } catch (exc) {
    return stringifiedFormData
  }
}

export const parseFormData = (data: any) => {
  return parseNestedJsonStrings(JSON.stringify(data))
}

export const parseEmploymentValues = ({
  els_soteorganisaatio,
  tyosuhdetoimiala,
  ammattinimike
}: any) => {
  const values: any = {}
  if (els_soteorganisaatio) {
    values.els_soteorganisaatio = JSON.parse(els_soteorganisaatio)
  }
  if (tyosuhdetoimiala) {
    values.tyosuhdetoimiala = JSON.parse(tyosuhdetoimiala)
  }
  if (ammattinimike) {
    values.ammattinimike = JSON.parse(ammattinimike)
  }
  return values
}

export const parseNewJobFormData = (data: CreateJobFormData): NewJob => {
  const { employment: parsedFormData } = parseNestedJsonStrings(JSON.stringify(data))
  const { ammattinimike, els_soteorganisaatio, els_tyosuhdesektori, tyosuhdetoimiala, ...rest } =
    parsedFormData
  return {
    els_ammattinimike: ammattinimike.els_koodiid,
    els_soteorganisaatio: els_soteorganisaatio.els_soteorganisaatiorekisteriid,
    els_tyosuhdesektori: els_tyosuhdesektori.attributevalue,
    els_tyosuhdetoimiala: tyosuhdetoimiala.attributevalue,
    ...rest
  }
}

export const parseDate = (date: string, dateFormat?: string) => {
  return format(parseISO(date), dateFormat || 'd.M.y')
}

export const yearList = () => {
  const start = 1930
  const end: number = new Date().getFullYear()

  return Array.from(Array(end - start + 1).keys())
    .map(x => x + start)
    .reverse()
}

export const validateIBAN: TestFunction<string | undefined, any> = bankAccount =>
  bankAccount !== undefined && IBAN.isValid(bankAccount)

const formatQuotedCsvValue = (value: any) =>
  value == undefined || value === '' ? '' : `"${value.toString().replace('"', '""')}"`

export const buildCsvLine = (values: any[]) => values.map(formatQuotedCsvValue).join(',')

export const replaceAll = (string: string, characterToReplace: string, replaceWith: string) => {
  return string.split(characterToReplace).join(replaceWith)
}
