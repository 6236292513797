import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  MenuItem,
  Select
} from '@mui/material'

import { format } from 'date-fns'
import React from 'react'
import { Controller } from 'react-hook-form'
import JobResultRow from '../../../components/JobResultRow'
import { ErrorMessage, FormInput, LaakariButton } from '../../../components/styled/uiComponents'
import { CommonFormSectionProps, IJobTitle, IWorkField } from '../../../types'

interface Props extends CommonFormSectionProps {
  /** Remove selected job and go back a step */
  removeJob: () => void
  jobValue: string
}

const EmploymentForm2: React.FC<Props> = ({
  register,
  removeJob,
  jobValue,
  control,
  errors,
  metaData: { toimialat, ammattinimikkeet }
}) => {
  const today = format(new Date(), 'yyyy-MM-d')
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h5' color='primary'>
          Työpaikka
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <JobResultRow jobInfo={JSON.parse(jobValue).els_longname} />
      </Grid>
      <Grid container justifyContent='flex-end' item xs={12}>
        <Grid item xs={12} md={4}>
          <LaakariButton variant='outlined' onClick={() => removeJob()}>
            VAIHDA
          </LaakariButton>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={12}>
          <FormLabel>Toimiala</FormLabel>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='employment.tyosuhdetoimiala'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                displayEmpty
                fullWidth
                onChange={e => onChange(e.target.value)}
                value={value || ''}>
                <MenuItem value=''>{'---Valitse---'}</MenuItem>
                {toimialat.map((workField: IWorkField) => (
                  <MenuItem
                    key={workField.value}
                    value={JSON.stringify({
                      attributevalue: workField.attributevalue,
                      value: workField.value
                    })}>
                    {workField.value}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <ErrorMessage>{errors.employment?.tyosuhdetoimiala?.message}</ErrorMessage>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Onko tämä päätoiminen työpaikkasi?</FormLabel>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={'employment.els_paatoimi'}
          control={control}
          defaultValue='yes'
          render={({ field: { onChange, value } }) => (
            <RadioGroup onChange={e => onChange(e.target.value)} value={value}>
              <FormControlLabel value='yes' control={<Radio />} label='Kyllä' />
              <FormControlLabel value='no' control={<Radio />} label='Ei' />
            </RadioGroup>
          )}
        />
      </Grid>
      <Grid container item>
        <Grid item xs={12}>
          <FormLabel>Ammattinimike</FormLabel>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='employment.ammattinimike'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                displayEmpty
                fullWidth
                onChange={e => onChange(e.target.value)}
                value={value || ''}>
                <MenuItem value=''>{'---Valitse---'}</MenuItem>
                {ammattinimikkeet.map(({ els_koodiid, els_koodinnimi }: IJobTitle) => (
                  <MenuItem
                    key={els_koodiid}
                    value={JSON.stringify({
                      els_koodiid: els_koodiid,
                      els_koodinnimi: els_koodinnimi
                    })}>
                    {els_koodinnimi}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <ErrorMessage>{errors.employment?.ammattinimike?.message}</ErrorMessage>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={12}>
          <FormLabel>Aloituspäivämäärä</FormLabel>
        </Grid>
        <Grid item xs={12}>
          <FormInput {...register('employment.els_alkupvm')} type='date' max={today} />
          <ErrorMessage>{errors.employment?.els_alkupvm?.message}</ErrorMessage>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default EmploymentForm2
