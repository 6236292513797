import React from 'react'
import { FormLabel, Grid, MenuItem, Select, Typography } from '@mui/material'

import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { ErrorMessage, FormInput } from '../../../../components/styled/uiComponents'

interface Props {
  register: UseFormRegister<any>
  control: Control<any, any>
  getValues: (name?: string) => any
  errors: any
  metaData?: any
}

const alternateUnions = [
  'Suomen Lääkäriliitto - 25 % Eesti Arstide Liit, virolainen jäsen',
  'Suomen Lääkäriliitto - 25 % Eläinlääkäriliiton jäsen',
  'Suomen Lääkäriliitto - 25 % Hammaslääkäriliiton jäsen',
  'Suomen Lääkäriliitto - 25 % Professoriliiton jäsen'
]

const AnotherUnionMember: React.FC<Props> = ({ register, errors, control }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography variant='h4' color='primary'>
            Toisen liiton jäsen
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} style={{ paddingTop: '3vh', paddingBottom: '2vh' }}>
          <FormLabel>Alennusperusteena oleava liitto</FormLabel>
          <Controller
            name='els_alennus.els_alennuksennimi'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                displayEmpty
                fullWidth
                onChange={e => onChange(e.target.value)}
                value={value || ''}>
                <MenuItem value=''>{'---Valitse---'}</MenuItem>
                {alternateUnions.map(union => (
                  <MenuItem value={union} key={union}>
                    {union}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <ErrorMessage>{errors?.els_alennus?.els_alennuksennimi?.message}</ErrorMessage>
        </Grid>

        <Grid item xs={12} md={6} style={{ paddingBottom: '3vh' }}>
          <FormLabel>Alennusperusteena olevan liiton jäsen vuodesta</FormLabel>
          <FormInput {...register('memberSinceYear')} />
          <ErrorMessage>{errors?.memberSinceYear?.message}</ErrorMessage>
        </Grid>

        <div style={{ borderTop: '1px dotted black', paddingTop: '3vh' }}>
          <Typography variant='h6' color='primary'>
            Jäsenmaksun mahdollinen palautus
          </Typography>
          <Grid item xs={12} md={6}>
            <FormLabel>
              Tilinumero jäsenmaksun mahdollista palautusta varten (IBAN muodossa)
            </FormLabel>
            <FormInput {...register('bankAccount')} />
            <ErrorMessage>{errors?.bankAccount?.message}</ErrorMessage>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

export default AnotherUnionMember
