const isValidKeys = (key: string, a: any, b: any) =>
  Object.prototype.hasOwnProperty.call(a, key) || Object.prototype.hasOwnProperty.call(b, key)

const compareStrings = (key: string, order?: 'asc' | 'desc') => (a: any, b: any) => {
  if (!isValidKeys(key, a, b)) {
    return 0
  }
  // sorting strings with non-ASCII characters
  const c = a[key].toUpperCase().localeCompare(b[key].toUpperCase())
  // ascending order by default
  return order === 'desc' ? c * -1 : c
}

const compareArrayOfStrings = (order?: 'asc' | 'desc') => (a: any, b: any) => {
  const c = a.toUpperCase().localeCompare(b.toUpperCase())
  return order === 'desc' ? c * -1 : c
}

export { compareStrings, compareArrayOfStrings }
